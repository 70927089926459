import palette from '../palette';
import typography from '../typography';

const TableCell = {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    '&$head': {
      ...typography.body1,
      backgroundColor: "rgb(249, 249, 252)"
    }
  },
}

export default TableCell;

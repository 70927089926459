import {
  SET_DETAILED_DASHBOARD_DATE_FORMAT,
  SET_DETAILED_DASHBOARD_PROPERTY,
  SET_DETAILED_DASHBOARD_TYPE,
  SET_OVERVIEW_DASHBOARD_DATE_FORMAT,
  SET_OVERVIEW_DASHBOARD_PROPERTY,
  SET_RISK_MANAGEMENT_INTEREST_RATES,
  SET_RISK_MANAGEMENT_VACANCIES,
} from "../actions/types";

const initialState = {
  overviewDashboard: {
    property: {
      value: "All properties",
      label: "All properties",
    },
    dateFormat: {
      value: "y10",
      label: "Next 10 years",
    },
  },
  detailedDashboard: {
    property: {
      value: "",
      label: "",
    },
    type: {
      value: "outflows",
      label: "Expenses",
    },
    dateFormat: {
      value: "m12",
      label: "Current",
    },
  },
  riskManagement: {
    interestRates: 0,
    vacancies: 100,
  },
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OVERVIEW_DASHBOARD_PROPERTY:
      return {
        ...state,
        overviewDashboard: {
          ...state.overviewDashboard,
          property: action.payload,
        },
      };
    case SET_OVERVIEW_DASHBOARD_DATE_FORMAT:
      return {
        ...state,
        overviewDashboard: {
          ...state.overviewDashboard,
          dateFormat: action.payload,
        },
      };
    case SET_DETAILED_DASHBOARD_PROPERTY:
      return {
        ...state,
        detailedDashboard: {
          ...state.detailedDashboard,
          property: action.payload,
        },
      };
    case SET_DETAILED_DASHBOARD_DATE_FORMAT:
      return {
        ...state,
        detailedDashboard: {
          ...state.detailedDashboard,
          dateFormat: action.payload,
        },
      };
    case SET_DETAILED_DASHBOARD_TYPE:
      return {
        ...state,
        detailedDashboard: {
          ...state.detailedDashboard,
          type: action.payload,
        },
      };
    case SET_RISK_MANAGEMENT_INTEREST_RATES:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          interestRates: action.payload,
        },
      };
    case SET_RISK_MANAGEMENT_VACANCIES:
      return {
        ...state,
        riskManagement: {
          ...state.riskManagement,
          vacancies: action.payload,
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BugReportIcon from "@material-ui/icons/BugReport";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ErrorIcon from "@material-ui/icons/Error";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LensIcon from "@material-ui/icons/Lens";
import LockIcon from "@material-ui/icons/Lock";
import SettingsIcon from "@material-ui/icons/Settings";
import TuneIcone from "@material-ui/icons/Tune";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { sendFeedback, signOut } from "../../actions/authActions";
import { setSidebarToggle } from "../../actions/layoutActions";
import palette from "../../theme/palette";
import "./Layout.scss";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#171b37",
    color: "#FFF",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    padding: "15px",
    display: "flex",
    backgroundColor: "#0a0a22",
  },
  brand: {
    color: "#FFF",
    position: "relative",
    marginLeft: "20px",
  },
  listText: {
    "& span": {
      color: "#FFF",
      fontWeight: "700",
    },
  },
  icon: {
    color: "#FFF",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  iconSB: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    fontSize: "14px",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

const Sidebar = ({ properties, isLoading, location, signOut, sendFeedback, auth, isAdmin }) => {
  const classes = useStyles();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [feedbackTitle, setFeedbackTitle] = useState("");
  const [feedback, setFeedback] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [typeOfSnackbar, setTypeOfSnackbar] = useState("success");
  const Icon = variantIcon[typeOfSnackbar];
  let snackbarMessage =
    "Thank you for submitting your feedback. We hope to continue improving your IPT experience!";
  if (typeOfSnackbar === "error") {
    snackbarMessage = "Sorry, there was an error trying to submit your feedback. Please try again.";
  }
  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <img src={"/logo.svg"} alt="Investment Property Tracker Logo" width={40} height={40} />
          <Typography variant={"h5"} className={classes.brand}>
            Investment Property Tracker
          </Typography>
        </div>
        <Divider light={true} />
        {isLoading && (
          <div
            style={{
              textAlign: "center",
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <ClipLoader color={"#2388E4"} />
          </div>
        )}
        {!isLoading && (
          <div style={{ overflow: "auto" }}>
            <List>
              <ListItem button component={Link} to={"/"} selected={location.pathname === "/"}>
                <ListItemIcon>
                  <DashboardIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Dashboard"} />
              </ListItem>
              <ListItem
                button
                component={Link}
                to={"/risk-management"}
                selected={location.pathname === "/risk-management"}
              >
                <ListItemIcon>
                  <TuneIcone className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Risk Management"} />
              </ListItem>
              <ListItem
                button
                component={Link}
                to={"/reports"}
                selected={location.pathname === "/reports"}
              >
                <ListItemIcon>
                  <AssignmentIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Reports"} />
              </ListItem>
              <ListItem
                button
                component={Link}
                to={"/settings"}
                selected={location.pathname === "/settings"}
              >
                <ListItemIcon>
                  <SettingsIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Settings"} />
              </ListItem>
              {isAdmin && (
                <ListItem
                  button
                  component={Link}
                  to={"/admin"}
                  selected={location.pathname === "/admin"}
                >
                  <ListItemIcon>
                    <LockIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText className={classes.listText} primary={"Admin"} />
                </ListItem>
              )}
            </List>
            <Divider light={true} />
            <List subheader={<ListSubheader component="div">MY PROPERTIES</ListSubheader>}>
              {properties.map((property, index) => (
                <ListItem
                  button
                  key={index}
                  component={Link}
                  to={`/properties/${property.id}`}
                  selected={location.pathname === `/properties/${property.id}`}
                >
                  <ListItemIcon>
                    <LensIcon
                      style={{
                        color: !property.hasOwnProperty("sale")
                          ? palette.info.light
                          : palette.error.light,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText className={classes.listText} primary={property.propertyName} />
                </ListItem>
              ))}
            </List>
            <Divider light={true} />
            <List subheader={<ListSubheader component="div">QUICK ACTIONS</ListSubheader>}>
              <ListItem
                button
                component={Link}
                to={"/properties/add-property"}
                selected={location.pathname === "/properties/add-property"}
              >
                <ListItemIcon>
                  <AddIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Add Property"} />
              </ListItem>
              <ListItem
                button
                component={Link}
                to={"/properties/add-transactions"}
                selected={location.pathname === "/properties/add-transactions"}
              >
                <ListItemIcon>
                  <AddIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Add Transactions"} />
              </ListItem>
              <ListItem
                button
                component={Link}
                to={"/properties/add-depreciation-schedule"}
                selected={location.pathname === "/properties/add-depreciation-schedule"}
              >
                <ListItemIcon>
                  <AddIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Add Depreciation Schedule"} />
              </ListItem>
            </List>
            <Divider light={true} />
            <List>
              <ListItem button onClick={() => setShowFeedbackDialog(true)}>
                <ListItemIcon>
                  <BugReportIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Feedback/Bugs"} />
              </ListItem>
            </List>
            <Divider light={true} />
            <List>
              <ListItem button onClick={signOut}>
                <ListItemIcon>
                  <ExitToAppIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.listText} primary={"Log out"} />
              </ListItem>
            </List>
          </div>
        )}
      </Drawer>
      <Dialog open={showFeedbackDialog} onClose={() => setShowFeedbackDialog(false)}>
        <DialogTitle>Bugs/Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please detail below any bugs, concerns or feedback that you may have so we can continue
            to improve your Investment Property Tracker experience. If you are reporting a bug, we
            would appreciate it if you could provide reproduction steps so we can address the issue
            quickly.
          </DialogContentText>
          <Form>
            <Grid container justify="space-between" spacing={2}>
              <Grid item xs={12}>
                <Form.Group>
                  <InputLabel>Title</InputLabel>
                  <Form.Control
                    type={"text"}
                    value={feedbackTitle}
                    onChange={(e) => setFeedbackTitle(e.target.value)}
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12}>
                <Form.Group>
                  <InputLabel>Comment</InputLabel>
                  <Form.Control
                    type={"text"}
                    value={feedback}
                    as="textarea"
                    rows="8"
                    onChange={(e) => setFeedback(e.target.value)}
                  />
                </Form.Group>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            size="small"
            color={"primary"}
            onClick={() => {
              setShowFeedbackDialog(false);
              setFeedbackTitle("");
              setFeedback("");
            }}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            size="small"
            color={"primary"}
            onClick={async () => {
              try {
                await sendFeedback(auth.user.uid, feedbackTitle, feedback);
                setTypeOfSnackbar("success");
                setFeedbackTitle("");
                setFeedback("");
              } catch (err) {
                setTypeOfSnackbar("error");
              } finally {
                setShowFeedbackDialog(false);
                setShowSnackbar(true);
              }
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={`top,center`}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <SnackbarContent
          className={clsx(classes[typeOfSnackbar], classes.margin)}
          message={
            <span className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {snackbarMessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => setShowSnackbar(false)}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </>
  );
};

Sidebar.propTypes = {
  setSidebarToggle: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  properties: _.orderBy(state.properties.allProperties, ["sale"], ["desc"]),
  sidebarToggle: state.layout.sidebarToggle,
  isLoading: state.layout.isLoading,
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, {
    setSidebarToggle,
    sendFeedback,
    signOut,
  })(Sidebar)
);

import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Form from "react-bootstrap/Form";
import {renderField} from "./FormHelpers";
import {required} from "./validate";

class SignUpForm extends Component {
  render() {
    const {handleSubmit, isLoading, err} = this.props;
    return (
        <Form onSubmit={handleSubmit} noValidate style={{
            width: "100%",
            marginTop: "8px"
        }}>
        <Field
          name="fullName"
          component={renderField}
          type="text"
          label="Name"
          required validate={[ required ]}
        />
        <Field
          name="email"
          component={renderField}
          type="text"
          label="Email"
          required validate={[ required ]}
        />
        <Field
          name="password"
          component={renderField}
          autoComplete="new-password"
          type="password"
          label="Password"
          required validate={[ required ]}
        />
        <Field
          name="confirmPassword"
          component={renderField}
          autoComplete="new-password"
          type="password"
          label="Confirm password"
          required validate={[ required ]}
        />
        <span className={"error"}>{err}</span>
          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{
                  margin: "24px 0 16px"
              }}
          >
              {!isLoading &&
              <span>Sign Up</span>
              }
              {isLoading &&
              <span className="spinner-border" style={{height: "24px", width: "24px"}} role="status" aria-hidden="true"></span>
              }
          </Button>
            <Grid container>
                <Grid item>
                    <Link href="/login" variant="body2">
                        {"Already have an account? Log in"}
                    </Link>
                </Grid>
            </Grid>
      </Form>
    );
  }
}

SignUpForm = reduxForm({
  form: 'signUp',
})(SignUpForm);

export default SignUpForm;

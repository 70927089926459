import MuiButton from "./MuiButton";
import MuiFormLabel from "./MuiFormLabel";
import MuiIconButton from "./MuiIconButton";
import MuiListSubheader from "./MuiListSubheader";
import MuiPaper from "./MuiPaper";
import MuiTableCell from "./MuiTableCell";
import MuiTableHead from "./MuiTableHead";
import MuiTableRow from "./MuiTableRow";
import MuiTypography from "./MuiTypography";

const overrides = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiTableRow,
  MuiFormLabel,
  MuiListSubheader,
}

export default overrides;

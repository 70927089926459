import { v4 as uuid } from "uuid";
import moment from "moment";

export const getDefaultProperty = (propertyId, loanId) => {
  return {
    id: propertyId,
    address: {
      postCode: "",
      state: "",
      streetAddress: "",
      suburb: "",
    },
    councilRates: 0,
    insurancePremium: 0,
    legal: 1200,
    loans: [
      {
        active: true,
        id: loanId,
        interestOnly: false,
        interestRate: 2.5,
        loanAmount: 375000,
        loanEndDate: "2049-07-01",
        loanName: "Loan 0",
        loanStartDate: "2019-07-01",
        loanTerm: 30,
      },
    ],
    maintenanceExpense: 10,
    managementFees: 10,
    propertyName: "Sample Property",
    purchaseDate: "2019-07-01",
    purchasePrice: 450000,
    rentalIncome: 450,
    stampDuty: 15000,
    strata: 0,
    valuations: [
      {
        amount: 450000,
        date: "2019-07-01",
        id: uuid(),
        initial: true,
        type: "formal",
      },
    ],
    waterRates: 0,
  };
};

export const getDefaultTransactions = (propertyId, loanId) => {
  const created = moment().toISOString();
  return {
    "03599240-9579-4c60-9975-ac5a230a67a0": {
      amount: 1500,
      comment: "",
      created,
      date: "2019-10-01",
      month: "2019-10",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "06cadbb2-9562-4a48-8c13-bed667918214": {
      amount: 832,
      comment: "",
      created,
      date: "2020-01-01",
      month: "2020-01",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "160674c1-d5e7-42c4-a6e2-ca82721b5ed8": {
      amount: 492,
      comment: "",
      created,
      date: "2020-04-01",
      month: "2020-04",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "2bdffc2c-ece4-4b88-bb4a-1ae8eb79195d": {
      amount: 1500,
      comment: "",
      created,
      date: "2020-06-01",
      month: "2020-06",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "31f8eb60-ad96-4254-a367-7873459d871e": {
      amount: 400,
      comment: "",
      created,
      date: "2019-08-01",
      month: "2019-08",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "321552a2-0f03-442d-99db-506933c902d3": {
      amount: 420,
      comment: "",
      created,
      date: "2020-05-01",
      month: "2020-05",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "34ea3209-dd53-424d-a307-e1d6ca5b7ab0": {
      amount: 908,
      comment: "",
      created,
      date: "2020-04-01",
      month: "2020-04",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "381815e0-81fa-4d1a-8bfa-d8b6cfac0f44": {
      amount: 568,
      comment: "",
      created,
      date: "2020-01-01",
      month: "2020-01",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "3ddfc9eb-62ca-4ace-9bb7-e83bd88cc463": {
      amount: 980,
      comment: "Repair fence wall. Agent paid.",
      created,
      date: "2020-03-01",
      month: "2020-03",
      propertyId,
      typeOfEntry: "maintenanceExpense",
    },
    "3e8bc86c-6ed4-4c7d-b956-5c529eed2641": {
      amount: 450,
      comment: "",
      created,
      date: "2019-12-01",
      month: "2019-12",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "4e0e22bf-0512-4750-b130-f01127cb23a8": {
      amount: 960,
      comment: "",
      created,
      date: "2020-02-01",
      month: "2020-02",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "51e24a3f-dc7d-47ad-867f-03a6024c67fd": {
      amount: 950,
      comment: "",
      created,
      date: "2019-09-01",
      month: "2019-09",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "5a642b38-8dc4-41e1-b032-d33e98ada52f": {
      amount: 870,
      comment: "",
      created,
      date: "2020-06-01",
      month: "2020-06",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "60179f40-9ed8-4f51-aef9-6fc4b87dc8da": {
      amount: 1000,
      comment: "",
      created,
      date: "2019-08-01",
      month: "2019-08",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "6d71870f-2c7f-4cfc-92f7-99c72cd57fe9": {
      amount: 440,
      comment: "",
      created,
      date: "2020-02-01",
      month: "2020-02",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "6e2eab1c-50ec-437c-ae91-fb725dd24338": {
      amount: 1500,
      comment: "",
      created,
      date: "2019-09-01",
      month: "2019-09",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "6e794ff4-3846-471a-80b7-2e21523c113b": {
      amount: 1320,
      comment: "",
      created,
      date: "2019-12-01",
      month: "2019-12",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "7afa2337-52e0-4a67-95b7-31dd01cbaebe": {
      amount: 980,
      comment: "",
      created,
      date: "2019-10-01",
      month: "2019-10",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "7e5728ed-75a5-4335-8ca8-8a904cd785ef": {
      amount: 1400,
      comment: "",
      created,
      date: "2020-07-01",
      month: "2020-07",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "8896b792-62ff-4172-9aa8-485f8f8017c7": {
      amount: 420,
      comment: "",
      created,
      date: "2019-10-01",
      month: "2019-10",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "89963913-8c3d-403c-8d74-75fa071fed2b": {
      amount: 429,
      comment: "",
      created,
      date: "2019-11-01",
      month: "2019-11",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "939aabe9-142e-4e8c-bce3-7283908cefa3": {
      amount: 530,
      comment: "",
      created,
      date: "2020-06-01",
      month: "2020-06",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "9bbe6303-ee9f-4a2d-88c1-aa736e61e044": {
      amount: 1500,
      comment: "",
      created,
      date: "2020-02-01",
      month: "2020-02",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "a025cd7d-b1cd-41b4-94b9-88124142b834": {
      amount: 450,
      comment: "",
      created,
      date: "2019-08-01",
      month: "2019-08",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "b2d348af-ab6a-4a31-85e9-8bce6ebced2b": {
      amount: 1500,
      comment: "",
      created,
      date: "2020-01-01",
      month: "2020-01",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "b480b6a3-9c40-4254-a85e-a0061517a090": {
      amount: 950,
      comment: "",
      created,
      date: "2019-12-01",
      month: "2019-12",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "b5dc2553-d9fe-433f-ac57-2021a1b703d4": {
      amount: 971,
      comment: "",
      created,
      date: "2019-11-01",
      month: "2019-11",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "b8336b27-bbbe-405d-8dd0-836fed59bd06": {
      amount: 450,
      comment: "",
      created,
      date: "2019-09-01",
      month: "2019-09",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
    "bcb85222-8063-423e-9680-33afaf9d2094": {
      amount: 1500,
      comment: "",
      created,
      date: "2020-03-01",
      month: "2020-03",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "c4001e4d-9821-4da6-b22f-98247e258fa2": {
      amount: 980,
      comment: "",
      created,
      date: "2020-05-01",
      month: "2020-05",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "ca170cd7-723a-4565-ba13-99c2d217315c": {
      amount: 907,
      comment: "",
      created,
      date: "2020-03-01",
      month: "2020-03",
      propertyId,
      typeOfEntry: `interestExpense_${loanId}`,
    },
    "cf4cb91b-fc5c-484b-b3c4-20fd6ba2c324": {
      amount: 200,
      comment: "",
      created,
      date: "2019-11-01",
      month: "2019-11",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "d69d1f29-d26e-4613-bbfc-b94007e3810a": {
      amount: 1468,
      comment: "Yearly council rates",
      created,
      date: "2019-09-01",
      month: "2019-09",
      propertyId,
      typeOfEntry: "councilRates",
    },
    "ec9be507-1b71-4ddb-b7d3-74f9f257b182": {
      amount: 1500,
      comment: "",
      created,
      date: "2020-05-01",
      month: "2020-05",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "ed2e3019-0c2e-4f1d-826c-0092233607e2": {
      amount: 1500,
      comment: "",
      created,
      date: "2020-04-01",
      month: "2020-04",
      propertyId,
      typeOfEntry: "rentalIncome",
    },
    "fbe192c4-381c-44c2-b12d-0255b1128bcc": {
      amount: 493,
      comment: "",
      created,
      date: "2020-03-01",
      month: "2020-03",
      propertyId,
      typeOfEntry: `principalRepayment_${loanId}`,
    },
  };
};

import {GET_ALL_LEDGER_ENTRIES, RESET_LEDGER_ENTRIES, SET_SAVING_LEDGER_ENTRIES} from "../actions/types";
import _ from "lodash";

const initialState = {
  ledgerEntries: [],
  selectedProperty: "",
  saving: false
};

const ledgerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LEDGER_ENTRIES:
      return {
        ...state,
        ledgerEntries: _.orderBy(action.payload, ["date"], ["desc"])
      };
    case RESET_LEDGER_ENTRIES:
      return {
        ...state,
        ledgerEntries: [],
      };
    case SET_SAVING_LEDGER_ENTRIES:
      return {
        ...state,
        saving: action.payload
      };
    default:
      return state;
  }
}

export default ledgerReducer;

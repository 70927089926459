import { GET_ALL_LEDGER_ENTRIES, SET_SAVING_LEDGER_ENTRIES } from "./types";
import { snapshotToFlatArray } from "../utils/helpers";
import { dbRef } from "../firebase";
import moment from "moment";

export const addLedgerEntries = (entries, propertyId, uid) => async (
  dispatch
) => {
  console.log("Adding ledger entries", entries, propertyId);
  let updates = {};
  entries.map((entry) => {
    const entryId = entry.id;
    delete entry.id;
    return (updates[`${entryId}`] = entry);
  });
  try {
    await dbRef.child(`ledgers/${uid}/${propertyId}`).update(updates);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addSampleTransactions = async (entries, propertyId, uid) => {
  console.log("Adding default ledger entries", entries, propertyId);
  try {
    await dbRef.child(`ledgers/${uid}/${propertyId}`).update(entries);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteLedgerEntries = (entries, propertyId, uid) => async (
  dispatch
) => {
  console.log("Deleting ledger entries", entries);
  let updates = {};
  entries.forEach((entryId) => {
    updates[`${entryId}`] = null;
  });
  try {
    await dbRef.child(`ledgers/${uid}/${propertyId}`).update(updates);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const deleteAllPropertyLedgerEntries = (propertyId, uid) => async (
  dispatch
) => {
  console.log("Deleting ledger entries for:", propertyId);
  try {
    await dbRef.child(`ledgers/${uid}/${propertyId}`).set(null);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateLedgerEntries = (entries, propertyId, uid) => async (
  dispatch
) => {
  console.log("Updating entries", entries, propertyId);
  let updates = {};
  const keysToInclude = ["comment", "date", "amount"];
  entries.forEach((entry) => {
    Object.keys(entry).forEach((key) => {
      if (keysToInclude.includes(key)) {
        updates[`${entry.id}/${key}`] = entry[key];
        if (key === "date") {
          updates[`${entry.id}/month`] = moment(entry[key]).format("YYYY-MM");
        }
      }
    });
  });
  try {
    await dbRef.child(`ledgers/${uid}/${propertyId}`).update(updates);
  } catch (err) {
    console.log(err);
    return err;
  }
};

// Get all ledger entries
export const getAllLedgerEntries = (uid) => async (dispatch) => {
  console.log("Getting all ledger entries", uid);
  try {
    let val = [];
    const snapshot = await dbRef.child(`ledgers/${uid}`).once("value");
    if (snapshot.val()) {
      val = snapshotToFlatArray(snapshot.val());
    }
    dispatch({ type: GET_ALL_LEDGER_ENTRIES, payload: val });
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const setSavingLedgerEntries = (toggle) => (dispatch) => {
  dispatch({
    type: SET_SAVING_LEDGER_ENTRIES,
    payload: toggle,
  });
};

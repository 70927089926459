import {
  GET_ALL_CASHFLOWS,
  SET_RISK_MANAGEMENT_CASHFLOWS,
  SET_UPDATED_RISK_MANAGEMENT_CASHFLOWS,
  UPDATE_CASHFLOWS
} from "./types";
import * as _ from "lodash";
import moment from "moment";
import {generateCashflows, generateUpdatedCashflows} from "../utils/cashflows";

// Get cashflows
export const getAllCashflows = (properties, entries, user) => async dispatch => {
  console.log("Getting all cashflows");
  if (properties && entries && user) {
    const cashflows = properties.map(property => {
      const initialBalances = property.loans ? property.loans.map(loan => {
        return moment(loan.loanStartDate).isSameOrBefore(property.purchaseDate, "months")
          ? loan.loanAmount : 0;
      }) : [];
      const tracker = Object.assign({},
        {
          propertyId: property.id,
          cashflows: generateCashflows(property, user),
          initialLoanAmounts: initialBalances,
          startYear: moment(property.purchaseDate).format("YYYY")
        });
      return generateUpdatedCashflows(
        tracker,
        [],
        entries.filter(entry => entry.propertyId === property.id),
        property.loans
      );
    });
    dispatch({
      type: GET_ALL_CASHFLOWS,
      payload: cashflows
    });
  }
};

export const updateWithNewCashflows = cashflow => async dispatch => {
  dispatch({
    type: UPDATE_CASHFLOWS,
    payload: cashflow
  });
};

export const setRiskManagementCashflows = cashflows => dispatch => {
  const data = cashflows.map(property => {
    let updatedProperty = JSON.parse(JSON.stringify(property));
    const currentCfIndex = updatedProperty.cashflows.findIndex(cf => cf.month === moment().format("YYYY-MM"));
    updatedProperty.cashflows = updatedProperty.cashflows.filter((cf, index) => index >= currentCfIndex && index <= currentCfIndex + 12);
    return updatedProperty;
  });
  dispatch({
    type: SET_RISK_MANAGEMENT_CASHFLOWS,
    payloadA: data,
    payloadB: _.cloneDeep(data)
  });
};

export const setUpdatedRiskManagementCashflows = cashflows => dispatch => {
  dispatch({
    type: SET_UPDATED_RISK_MANAGEMENT_CASHFLOWS,
    payload: cashflows
  });
};

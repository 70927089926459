import _ from "lodash";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { mapKeyToName } from "./helpers";

const straightLine = (value, date, effectiveLife) => {
  let yearlyPayments = [];
  let currentValue = value;
  let yearlyPercentage = 1 / effectiveLife;
  for (let i = 0; i < effectiveLife; i++) {
    let yearDep = 0;
    const year = moment(date).year();
    let paymentDate = moment(date).isSameOrAfter(moment(`${year}-07-01`))
      ? moment(`${year + 1}-06-30`)
      : moment(`${year}-06-30`);
    if (i === 0) {
      yearDep = value * yearFrac(date, paymentDate) * yearlyPercentage;
    } else {
      yearDep = value * yearlyPercentage;
    }
    yearlyPayments.push(yearDep);
    currentValue -= yearDep;
  }
  if (currentValue > 0) {
    yearlyPayments.push(currentValue);
  }
  return yearlyPayments;
};

const yearFrac = (startDate, endDate) => {
  console.log(startDate, endDate.toString());
  let sdate = moment(startDate);
  let edate = moment(endDate);
  // Return zero if start_date and end_date are the same
  if (sdate === edate) return 0;
  // Swap dates if start_date is later than end_date
  if (sdate.diff(edate) > 0) {
    edate = moment(startDate);
    sdate = moment(endDate);
  }
  return edate.diff(sdate, "days") / 365;
};

export const generateDepreciationScheduleEntries = (entries, startYear, propertyId, depType) => {
  let firstEntryId = "";
  return entries.map((entry, index) => {
    const paymentDate = moment(`${startYear + index}-06-30`);
    if (index === 0) {
      firstEntryId = uuid();
      return {
        typeOfEntry: "depreciationAndAmortisedSettlementFees",
        id: firstEntryId,
        amount: entry,
        date: paymentDate.format("YYYY-MM-DD"),
        month: paymentDate.format("YYYY-MM"),
        comment: `Depreciation schedule payment #1 - ${mapKeyToName(depType)}`,
        propertyId: propertyId,
        created: moment().toISOString(),
      };
    } else {
      return {
        typeOfEntry: "depreciationAndAmortisedSettlementFees",
        id: uuid(),
        parentId: firstEntryId,
        amount: entry,
        date: paymentDate.format("YYYY-MM-DD"),
        month: paymentDate.format("YYYY-MM"),
        comment: `Depreciation schedule payment #${index + 1} - ${mapKeyToName(depType)}`,
        propertyId: propertyId,
        created: moment().toISOString(),
      };
    }
  });
};

export const generateRefinanceLedgerEntry = (balance, date, propertyId, index) => {
  return {
    date: moment(date).format("YYYY-MM-DD"),
    month: moment(date).format("YYYY-MM"),
    typeOfEntry: `loanRefinance${index}`,
    id: uuid(),
    created: moment().toISOString(),
    propertyId: propertyId,
    amount: parseFloat(balance),
  };
};

const getCapitalWorkTransactions = (transaction, propertyId) => {
  const year = moment(transaction.date).year();
  let paymentDate = moment(transaction.date).isSameOrAfter(moment(`${year}-07-01`))
    ? moment(`${year + 1}-06-30`)
    : moment(`${year}-06-30`);

  if (transaction.typeOfDepreciation === "straightLine") {
    return straightLine(transaction.amount, transaction.date, transaction.effectiveLife).map(
      (payment, i) => {
        const secondaryTransaction = {
          typeOfEntry: "depreciationAndAmortisedSettlementFees",
          id: uuid(),
          amount: payment,
          date: paymentDate.format("YYYY-MM-DD"),
          month: paymentDate.format("YYYY-MM"),
          comment: "Payment #" + (i + 1) + " " + transaction.comment,
          parentId: transaction.id,
          propertyId: propertyId,
          created: moment().toISOString(),
        };
        paymentDate = moment(paymentDate).add(1, "years");
        return secondaryTransaction;
      }
    );
  }
  return [];
};

const getLoanRepaymentsTransactions = (transaction, interestExpense, propertyId) => {
  const loanRepaymentId = transaction.typeOfEntry.split("_")[1];
  let transactions = [];
  console.log(transaction, interestExpense);
  if (interestExpense && interestExpense !== 0) {
    transactions.push({
      typeOfEntry: `interestExpense_${loanRepaymentId}`,
      id: uuid(),
      amount: parseFloat(interestExpense),
      month: moment(transaction.date).format("YYYY-MM"),
      date: moment(transaction.date).format("YYYY-MM-DD"),
      propertyId: propertyId,
      created: moment().toISOString(),
      comment: transaction.comment,
    });
  }
  if (interestExpense && transaction.amount - interestExpense !== 0) {
    transactions.push({
      typeOfEntry: `principalRepayment_${loanRepaymentId}`,
      id: uuid(),
      amount: parseFloat(transaction.amount - interestExpense),
      month: moment(transaction.date).format("YYYY-MM"),
      date: moment(transaction.date).format("YYYY-MM-DD"),
      propertyId: propertyId,
      created: moment().toISOString(),
      comment: transaction.comment,
    });
  }
  return transactions;
};

const getRentalTransactions = (transaction, managementFees, propertyId) => {
  if (managementFees !== 0 && managementFees !== undefined) {
    return {
      typeOfEntry: `managementFees`,
      id: uuid(),
      amount: managementFees,
      month: moment(transaction.date).format("YYYY-MM"),
      date: moment(transaction.date).format("YYYY-MM-DD"),
      propertyId: propertyId,
      created: moment().toISOString(),
      comment: transaction.comment,
    };
  }
  return null;
};

export const generateLedgerEntries = (entries, propertyId) => {
  let newEntries = [];
  entries.forEach((entry) => {
    let updatedEntry = Object.assign(
      {},
      {
        id: uuid(),
        date: moment(entry.date).format("YYYY-MM-DD"),
        month: moment(entry.date).format("YYYY-MM"),
        created: moment().toISOString(),
        propertyId: propertyId,
        amount: parseFloat(entry.amount),
        comment: entry.comment || "",
        typeOfEntry: entry.typeOfEntry,
      }
    );
    if (updatedEntry.typeOfEntry === "capitalWorks") {
      updatedEntry = Object.assign(updatedEntry, {
        typeOfDepreciation: entry.typeOfDepreciation,
      });
      if (entry.typeOfDepreciation === "straightLine") {
        updatedEntry = Object.assign(updatedEntry, {
          effectiveLife: entry.effectiveLife,
        });
      }
      const capitalWorksTransactions = getCapitalWorkTransactions(updatedEntry, propertyId);
      newEntries.push(...capitalWorksTransactions);
    } else if (updatedEntry.typeOfEntry.includes("loanRepayment")) {
      const loanRepaymentTransactions = getLoanRepaymentsTransactions(
        updatedEntry,
        entry.interestExpense,
        propertyId
      );
      newEntries.push(...loanRepaymentTransactions);
      return;
    } else if (updatedEntry.typeOfEntry === "rentalIncome") {
      const managementFees = getRentalTransactions(updatedEntry, entry.managementFees, propertyId);
      if (managementFees) {
        newEntries.push(managementFees);
      }
    }
    newEntries.push(updatedEntry);
  });
  console.log(newEntries);
  return newEntries;
};

export const mapLedgerEntryTypeToName = (name, loans) => {
  if (name && name.includes("interestExpense") && loans && loans.length > 0) {
    const loanRepaymentNum = loans.findIndex((loan) => loan.id === name.split("_")[1]);
    return loanRepaymentNum !== -1 ? `Interest expense - ${loans[loanRepaymentNum].loanName}` : "";
  } else if (name && name.includes("principalRepayment") && loans && loans.length > 0) {
    const loanRepaymentNum = loans.findIndex((loan) => loan.id === name.split("_")[1]);
    return loanRepaymentNum !== -1 ? `Principal payment - ${loans[loanRepaymentNum].loanName}` : "";
  } else if (name && name.includes("loanRefinance") && loans && loans.length > 0) {
    const loanRepaymentNum = loans.findIndex((loan) => loan.id === name.split("_")[1]);
    return loanRepaymentNum !== -1 ? `Loan refinance - ${loans[loanRepaymentNum].loanName}` : "";
  } else if (name) {
    return mapKeyToName(name);
  } else {
    return "";
  }
};

const otherLedgerDropdownItems = [
  {
    key: "capitalWorks",
    name: "Capital Assets (e.g. paint, carpets, establishment fees)",
  },
  {
    key: "councilRates",
    name: "Council Rates",
  },
  {
    key: "depreciationAndAmortisedSettlementFees",
    name: "Depreciation/Amortisation",
  },
  {
    key: "waterRates",
    name: "Water Rates",
  },
  {
    key: "managementFees",
    name: "Management Fees",
  },
  {
    key: "strata",
    name: "Strata/Body Corporate",
  },
  {
    key: "insurancePremium",
    name: "Insurance Premium",
  },
  {
    key: "maintenanceExpense",
    name: "Maintenance",
  },

  {
    key: "otherExpenses",
    name: "Other Expenses",
  },
];

export const ledgerDropdownList = [
  {
    key: "rentalIncome",
    name: "Rental Income",
  },
  {
    key: "loanRepayment",
    name: "Loan Repayment",
  },
  {
    key: "empty",
    name: "",
  },
  ..._.orderBy(otherLedgerDropdownItems, ["name"], ["asc"]),
];

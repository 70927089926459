import _ from "lodash";
import moment from "moment";
import * as numeral from "numeral";
import { sumAllKeys } from "./cashflows";
import { mapLedgerEntryTypeToName } from "./ledger";

export const entryTypes = {
  INFLOWS: "inflows",
  OUTFLOWS: "outflows",
  MULTI: "multi",
  NORMAL: "normal",
};

export const snapshotToArray = (snapshot) =>
  Object.entries(snapshot).map((e) => Object.assign(e[1], { id: e[0] }));

export const snapshotToFlatArray = (snapshot) =>
  Object.entries(snapshot)
    .map((e) => Object.entries(e[1]).map((ee) => Object.assign(ee[1], { id: ee[0] })))
    .flat();

export const sumMultiObjectsInArray = (arr) => {
  const keys = arr && arr.length > 0 ? Object.keys(arr[0]) : [];
  return _.zipObject(
    keys,
    keys.map((key) => _.sum(_.map(arr, key)))
  );
};

export const sumMultiObjectsInArrayNested = (arr) => {
  let res = arr && arr.length > 0 ? arr[0] : [];
  if (arr && arr.length > 1) {
    for (let i = 1; i < arr.length; i++) {
      let currArr = arr[i];
      res.forEach((item, ind) => {
        item.amount += currArr[ind].amount;
      });
    }
  }
  return res;
};

export const getEndMonthIndex = (startIndex, cfLength, num) => {
  const endIndex = startIndex + num;
  return cfLength > endIndex ? endIndex : cfLength;
};

export const getFormatAndNum = (dateFormat) => {
  const format = dateFormat[0];
  const num = parseInt(dateFormat.substring(1));
  return { format, num };
};

export const convertToFinancialYear = (date) => {
  return moment(date).quarter() <= 2 ? moment(date).year() : 1 + moment(date).year();
};

export const convertTypeToTitle = (title) => {
  switch (title) {
    case "netCashflow":
      return "Cashflow";
    case "netCashflowPostPrincipal":
      return "Cashflow post principal payments";
    case "netCashflowPostDep":
      return "Taxable income";
    case "balanceCashflows":
      return "Debt";
    default:
      return "";
  }
};

export const generateGraphData = (cashflows, properties, chartType, dateFormat) => {
  let periodRange = [];
  const { format, num } = getFormatAndNum(dateFormat);
  const currentFY = parseInt(convertToFinancialYear(moment()));
  const data = cashflows.map((property, index) => {
    const label = properties.find((p) => p.id === property.propertyId).propertyName;
    switch (format) {
      case "m":
        let cfs = [];
        let startMonth = moment();
        let numMonths = 24;
        switch (num) {
          case 0:
            cfs = property.cashflows.filter(
              (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY + 1
            );
            cfs.shift();
            startMonth = startMonth.add(1, "month");
            numMonths = 12;
            break;
          case 12:
            cfs = property.cashflows.filter(
              (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY - 1
            );
            startMonth = moment(currentFY - 2 + "-07-01");
            break;
          default:
            cfs = property.cashflows.filter(
              (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY + 1
            );
            startMonth = moment(currentFY - 1 + "-07-01");
        }
        let currentMonth = startMonth;
        let valsMonthly = [];
        for (let i = 0; i < numMonths; i++) {
          const formattedMonth = moment(currentMonth).format("YYYY-MM");
          const cf = cfs.find((a) => a.month === formattedMonth);
          if (index === 0) {
            periodRange.push(moment(currentMonth).format("MMM-YY"));
          }
          if (!cf) {
            valsMonthly.push(null);
          } else {
            if (Array.isArray(cf[chartType])) {
              valsMonthly.push(_.sum(cf[chartType]));
            } else {
              valsMonthly.push(cf[chartType]);
            }
          }
          currentMonth = moment(currentMonth).add(1, "month");
        }
        return { label, data: valsMonthly };
      case "y":
      default:
        const currentYear = parseInt(moment().format("YYYY"));
        const endYear = num === 0 ? 2060 : currentYear + num;
        let valsYearly = [];
        for (let i = currentYear; i <= endYear; i++) {
          if (index === 0) {
            periodRange.push("FY" + i.toString().substr(2, 2));
          }
          let currentVal = 0;
          if (chartType === "valuation") {
            const currentYearCFs = property.cashflows.filter((cf) => cf.financialYear === i);
            currentVal =
              currentYearCFs.length > 0 ? currentYearCFs[currentYearCFs.length - 1][chartType] : 0;
          } else {
            currentVal = property.cashflows
              .filter((cf) => cf.financialYear === i)
              .reduce((a, b) => {
                return Array.isArray(b[chartType]) ? _.sum(b[chartType]) : a + b[chartType];
              }, 0);
          }
          valsYearly.push(currentVal < 1 && currentVal >= 0 ? 0 : currentVal);
        }
        return { label, data: valsYearly };
    }
  });
  return { data, periodRange };
};

export const generateCashflowGraphData = (cashflows, properties, chartTypes, dateFormat) => {
  let periodRange = [];
  const { format, num } = getFormatAndNum(dateFormat);
  const currentFY = parseInt(convertToFinancialYear(moment()));
  const graphData = cashflows.map((property, index) => {
    let data = chartTypes.map((ct) => ({
      label: convertTypeToTitle(ct),
      key: ct,
      data: [],
    }));
    switch (format) {
      case "m":
        let cfs = [];
        let startMonth = moment();
        let numMonths = 24;
        switch (num) {
          case 0:
            cfs = property.cashflows.filter(
              (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY + 1
            );
            cfs.shift();
            startMonth = startMonth.add(1, "month");
            numMonths = 12;
            break;
          case 12:
            cfs = property.cashflows.filter(
              (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY - 1
            );
            startMonth = moment(currentFY - 2 + "-07-01");
            break;
          default:
            cfs = property.cashflows.filter(
              (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY + 1
            );
            startMonth = moment(currentFY - 1 + "-07-01");
        }
        let currentMonth = startMonth;
        for (let i = 0; i < numMonths; i++) {
          const formattedMonth = moment(currentMonth).format("YYYY-MM");
          const cf = cfs.find((a) => a.month === formattedMonth);
          if (index === 0) {
            periodRange.push(moment(currentMonth).format("MMM-YY"));
          }
          if (!cf) {
            data.forEach((item) => {
              item.data.push(null);
            });
          } else {
            data.forEach((item) => {
              if (Array.isArray(cf[item.key])) {
                item.data.push(_.sum(cf[item.key]));
              } else {
                item.data.push(cf[item.key]);
              }
            });
          }
          currentMonth = moment(currentMonth).add(1, "month");
        }
        return { data, periodRange };
      case "y":
      default:
        const currentYear = parseInt(moment().format("YYYY"));
        const endYear = num === 0 ? 2060 : currentYear + num;
        for (let i = currentYear; i <= endYear; i++) {
          if (index === 0) {
            periodRange.push("FY" + i.toString().substr(2, 2));
          }
          const currentYearCfs = property.cashflows.filter((cf) => cf.financialYear === i);
          data.forEach((item) => {
            item.data.push(
              currentYearCfs.reduce((a, b) => {
                return Array.isArray(b[item.key]) ? a + _.sum(b[item.key]) : a + b[item.key];
              }, 0)
            );
          });
        }
        return { data, periodRange };
    }
  });
  return graphData;
};

export const generateAnnualisedTableData = (cfs = [], properties = [], valuation) => {
  valuation = valuation === 0 ? 1 : valuation;
  const rentalIncome = properties.reduce((a, b) => a + b.rentalIncome * 52, 0);
  const netRentalIncome = properties.reduce(
    (a, b) => a + b.rentalIncome * (1 - b.maintenanceExpense / 100 - b.managementFees / 100) * 52,
    0
  );
  const strata = properties.reduce((a, b) => a + b.strata * 4, 0);
  const water = properties.reduce((a, b) => a + b.waterRates, 0);
  const council = properties.reduce((a, b) => a + b.councilRates, 0);
  const insurance = properties.reduce((a, b) => a + b.insurancePremium, 0);
  const grossYield = rentalIncome / valuation;
  const netYield = (netRentalIncome - strata - water - council - insurance) / valuation;
  return [
    {
      key: "grossYield",
      label: "Gross Yield",
      tooltip: "",
      amount: grossYield,
    },
    {
      key: "netYield",
      label: "Net Yield",
      tooltip: "",
      amount: netYield,
    },
    {
      key: "rentalIncome",
      label: "Rental Income",
      tooltip: "",
      type: "currency",
      amount: rentalIncome,
    },
  ];
};

export const generateDetailedPropertyGraphData = (cashflows = [], chartType) => {
  const historical = cashflows.filter((cf) => moment(cf.month).isSameOrBefore(moment(), "months"));
  let periodRange = [];
  let data = [];
  if (historical.length > 0) {
    if (chartType === "Summary") {
      data = [
        {
          label: "Income",
          data: [],
          key: "inflows",
        },
        {
          label: "Expenses",
          data: [],
          key: "outflows",
        },
      ];
      periodRange = historical.map((cf) => moment(cf.month).format("MMM-YY"));
      historical.forEach((cf) => {
        data.forEach((item) => {
          item.data.push(sumAllKeys(cf[item.key]));
        });
      });
      let net = {
        label: "Net income",
        data: sumArrays(data.map((a) => a.data)),
        key: "netIncome",
        type: "line",
      };
      data.push(net);
    } else {
      const keys = Object.keys(historical[0]["inflows"]).concat(
        Object.keys(historical[0]["outflows"])
      );
      data = keys.map((key) => {
        return { label: mapKeyToName(key), data: [], key: key, hidden: false };
      });
      periodRange = historical.map((cf) => moment(cf.month).format("MMM-YY"));
      historical.forEach((cf) => {
        data.forEach((item) => {
          if (item.key === "rentalIncome") {
            item.data.push(cf["inflows"][item.key]);
          } else if (Array.isArray(cf["outflows"][item.key])) {
            item.data.push(_.sum(cf["outflows"][item.key]));
          } else {
            item.data.push(cf["outflows"][item.key]);
          }
        });
        data.forEach((item) => {
          const net = item.data.reduce((a, b) => a + b, 0);
          item.hidden = net === 0;
        });
      });
    }
  }
  return { data, periodRange };
};

export const generateDetailedGraphData = (property, chartType, dateFormat) => {
  let periodRange = [];
  const { format, num } = getFormatAndNum(dateFormat);
  const currentFY = parseInt(convertToFinancialYear(moment()));
  if (!property) {
    return { data: [], periodRange };
  } else {
    let data = Object.keys(property.cashflows[0][chartType]).map((key) => {
      return { label: mapKeyToName(key), data: [], key: key };
    });
    switch (format) {
      case "m":
        const cfs =
          num === 12
            ? property.cashflows.filter(
                (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY - 1
              )
            : property.cashflows.filter(
                (cf) => cf.financialYear === currentFY || cf.financialYear === currentFY + 1
              );
        const startMonth =
          num === 12 ? moment(currentFY - 2 + "-07-01") : moment(currentFY - 1 + "-07-01");
        let currentMonth = startMonth;
        for (let i = 0; i < 24; i++) {
          const formattedMonth = moment(currentMonth).format("YYYY-MM");
          const cf = cfs.find((a) => a.month === formattedMonth);
          periodRange.push(moment(currentMonth).format("MMM-YY"));
          if (!cf) {
            data.forEach((item) => {
              item.data.push(null);
            });
          } else {
            data.forEach((item) => {
              if (Array.isArray(cf[chartType][item.key])) {
                item.data.push(_.sum(cf[chartType][item.key]));
              } else {
                item.data.push(cf[chartType][item.key]);
              }
            });
          }
          currentMonth = moment(currentMonth).add(1, "month");
        }
        return { data, periodRange };
      case "y":
      default:
        const currentYear = parseInt(moment().format("YYYY"));
        const endYear = num === 0 ? 2060 : currentYear + num;
        for (let i = currentYear; i <= endYear; i++) {
          periodRange.push("FY" + i.toString().substr(2, 2));
          const currentYearCfs = property.cashflows.filter((cf) => cf.financialYear === i);
          data.forEach((item) => {
            item.data.push(
              currentYearCfs.reduce((a, b) => {
                return Array.isArray(b[chartType][item.key])
                  ? a + _.sum(b[chartType][item.key])
                  : a + b[chartType][item.key];
              }, 0)
            );
          });
        }
        return { data, periodRange };
    }
  }
};

export const generateReportSummaryData = (property, cashflows, financialYear) => {
  console.log(cashflows, property);
  let currentYearCfs = cashflows.cashflows.filter(
    (cf) =>
      cf.financialYear === +financialYear && moment(cf.date).isSameOrBefore(moment(), "months")
  );
  const loanBalances = currentYearCfs[currentYearCfs.length - 1].balanceCashflows;
  let data = {
    propertyName: property.propertyName,
    address: isEmptyMap(property.address)
      ? property.propertyName
      : convertToAddress(property.address),
    financialYear: `1 July ${financialYear - 1} - 30 June ${financialYear}`,
    summary: {
      income: currentYearCfs.reduce((a, b) => a + b.totalIncome, 0),
      expenses: currentYearCfs.reduce((a, b) => a + b.totalExpenses, 0),
      netIncome: 0,
      valuation: currentYearCfs[currentYearCfs.length - 1].valuation,
      debt: loanBalances.reduce((a, b) => a + b, 0),
      equity: 0,
    },
    cashflow: currentYearCfs.reduce((a, b) => a + b.netCashflow, 0),
    cashflowPostPrincipal: currentYearCfs.reduce((a, b) => a + b.netCashflowPostPrincipal, 0),
    taxableCashflow: currentYearCfs.reduce((a, b) => a + b.netCashflowPostDep, 0),
    loans: {
      loanValues: property.loans.map((p, i) => ({
        loanName: p.loanName,
        amount: loanBalances[i],
      })),
      total: loanBalances.reduce((a, b) => a + b, 0),
    },
    capitalBase: {
      purchasePrice: property.purchasePrice,
      legalFees: property.legal,
      stampDuty: property.stampDuty,
      other: property.capitalBase || [],
      total: 0,
    },
    inflows: {
      rentalIncome: currentYearCfs.reduce((a, b) => a + b.inflows.rentalIncome, 0),
    },
    outflows: {
      interestExpenses: addMultiArrays(currentYearCfs.map((a) => a.outflows.interestExpenses)),
      capitalWorks: currentYearCfs.reduce((a, b) => a + b.outflows.capitalWorks, 0),
      otherExpenses: currentYearCfs.reduce((a, b) => a + b.outflows.otherExpenses, 0),
      councilRates: currentYearCfs.reduce((a, b) => a + b.outflows.councilRates, 0),
      waterRates: currentYearCfs.reduce((a, b) => a + b.outflows.waterRates, 0),
      managementFees: currentYearCfs.reduce((a, b) => a + b.outflows.managementFees, 0),
      insurancePremium: currentYearCfs.reduce((a, b) => a + b.outflows.insurancePremium, 0),
      maintenanceExpense: currentYearCfs.reduce((a, b) => a + b.outflows.maintenanceExpense, 0),
      strata: currentYearCfs.reduce((a, b) => a + b.outflows.strata, 0),
    },
    capitalExpenses: currentYearCfs.reduce(
      (a, b) => a + b.depreciationAndAmortisedSettlementFees,
      0
    ),
    principalRepayments: addMultiArrays(currentYearCfs.map((a) => a.principalRepayments)),
  };
  console.log(data.capitalBase.other, _.sumBy(data.capitalBase.other, "amount"));
  data.summary.netIncome = data.summary.income + data.summary.expenses;
  data.summary.equity = data.summary.valuation - data.summary.debt;
  data.capitalBase.total =
    data.capitalBase.purchasePrice +
    data.capitalBase.legalFees +
    data.capitalBase.stampDuty +
    _.sumBy(data.capitalBase.other, "amount");
  return data;
};

export const generateReportLedger = (ledgerEntries, property) => {
  let data = {
    income: [],
    cashExpenses: [],
    capitalExpenses: [],
  };
  ledgerEntries.forEach((entry) => {
    const comment = entry.comment ? " - " + entry.comment : "";
    const entryData = {
      date: moment(entry.date).format("DD-MMM-YYYY"),
      type: mapLedgerEntryTypeToName(entry.typeOfEntry, property ? property.loans : []) + comment,
      amount: formatNumber(entry.amount),
    };
    const classification = getLedgerClass(entry.typeOfEntry);
    switch (classification) {
      case entryTypes.INFLOWS:
        data.income.push(entryData);
        break;
      case entryTypes.OUTFLOWS:
        data.cashExpenses.push(entryData);
        break;
      case entryTypes.NORMAL:
      default:
        data.capitalExpenses.push(entryData);
        break;
    }
  });
  return data;
};

export const getClassification = (typeOfEntry) => {
  const entryName = getEntryName(typeOfEntry);
  switch (entryName) {
    case "rentalIncome":
      return entryTypes.INFLOWS;
    case "otherExpenses":
    case "capitalWorks":
    case "councilRates":
    case "waterRates":
    case "managementFees":
    case "insurancePremium":
    case "maintenanceExpense":
    case "strata":
      return entryTypes.OUTFLOWS;
    case "principalRepayment":
    case "interestExpense":
      return entryTypes.MULTI;
    default:
      return entryTypes.NORMAL;
  }
};

export const getLedgerClass = (typeOfEntry) => {
  const entryName = getEntryName(typeOfEntry);
  switch (entryName) {
    case "rentalIncome":
      return entryTypes.INFLOWS;
    case "otherExpenses":
    case "capitalWorks":
    case "councilRates":
    case "waterRates":
    case "managementFees":
    case "insurancePremium":
    case "maintenanceExpense":
    case "interestExpense":
    case "strata":
      return entryTypes.OUTFLOWS;
    default:
      return entryTypes.NORMAL;
  }
};

export const convertToAddress = (address) => {
  const { streetAddress, suburb, postCode, state } = address;
  return isEmptyMap(address)
    ? "No Address Entered."
    : `${streetAddress}, ${suburb}, ${state} ${postCode}`;
};

export const getEntryName = (typeOfEntry) => {
  return typeOfEntry.split("_")[0];
};

export const getEntryId = (typeOfEntry) => {
  return typeOfEntry.split("_")[1];
};

export const numberFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

export const formatNumber = (number) => numeral(number).format("($0,0.00)");
export const formatNumberAbs = (number) => numeral(Math.abs(number)).format("($0,0.00)");
export const formatNumberNoDecimals = (number) => numeral(number).format("($0,0)");
export const formatNumberNoDecimalsAbs = (number) => numeral(Math.abs(number)).format("($0,0)");
export const formatToNum = (s) => numeral(s).value();

export const parseAddressData = (streetAddress, suburb, state, postCode) =>
  `${streetAddress
    .toLowerCase()
    .replace("/", "-")
    .replace(/ /g, "-")}-${suburb.toLowerCase()}-${state.toLowerCase()}-${postCode}`;

export const sumArrays = (arr) => {
  let total = [];
  for (let i = 0, l0 = arr.length; i < l0; i++)
    for (let j = 0, arg = arr[i], l1 = arg.length; j < l1; j++)
      total[j] = (total[j] === undefined ? 0 : total[j]) + arg[j];
  return total;
};

export const addMultiArrays = (arrs) => {
  let results = [];
  if (arrs.length > 0) {
    const arrLen = arrs[0].length;
    for (let i = 0; i < arrLen; i++) {
      results.push(arrs.reduce((a, b) => a + b[i], 0));
    }
  }
  return results;
};

export const minusTwoArrays = (arr1, arr2) => {
  return arr1.map((a, i) => a - arr2[i]);
};

export const percentDiff = (num1, num2) => {
  if (num1 === 0) {
    num1 = 1;
  }
  return ((num2 - num1) / num1) * 100;
};

export const valDiff = (prev, curr) => {
  return curr - prev;
};

export const isEmptyMap = (object) => !Object.values(object).some((x) => x !== null && x !== "");

export const mapKeyToName = (key) => {
  switch (key) {
    case "rentalIncome":
      return "Rental income";
    case "interestExpenses":
      return "Interest expenses";
    case "otherExpenses":
      return "Other expenses";
    case "capitalWorks":
      return "Capital works";
    case "councilRates":
      return "Council rates";
    case "waterRates":
      return "Water rates";
    case "managementFees":
      return "Management fees";
    case "insurancePremium":
      return "Insurance premium";
    case "maintenanceExpense":
      return "Maintenance";
    case "strata":
      return "Strata/Body corporate";
    case "depreciationAndAmortisedSettlementFees":
      return "Depreciation/Amortised settlement fees";
    case "propertyName":
      return "Property name";
    case "purchaseDate":
      return "Purchase date";
    case "purchasePrice":
      return "Purchase price";
    case "legal":
      return "Legal fees";
    case "stampDuty":
      return "Stamp duty";
    case "buildingPest":
      return "Building and pest";
    case "principalRepayments":
      return "Principal repayments";
    case "netYield":
      return "Net yield";
    case "grossYield":
      return "Gross yield";
    case "building":
      return "Building";
    case "plantEquipment":
      return "Plant and equipment";
    default:
      return "Other";
  }
};

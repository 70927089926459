import {combineReducers} from "redux";
import errorReducer from "./errorReducer";
import cashflowReducer from "./cashflowReducer";
import filterReducer from "./filterReducer";
import propertyReducer from "./propertyReducer";
import ledgerReducer from "./ledgerReducer";
import {reducer as formReducer} from "redux-form";
import layoutReducer from "./layoutReducer";
import authReducer from "./authReducer";

const appReducer = combineReducers({
  errors: errorReducer,
  cashflows: cashflowReducer,
  filters: filterReducer,
  properties: propertyReducer,
  ledgerEntries: ledgerReducer,
  layout: layoutReducer,
  auth: authReducer,
  form: formReducer
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    localStorage.removeItem("authUser");
    state = undefined;
  }
  return appReducer(state, action);
};

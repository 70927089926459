import palette from '../palette';

const TableRow = {
  root: {
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.default
      }
    },
  },
}

export default TableRow;

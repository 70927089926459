import {
  ADD_LOAN_ENTRY,
  ADD_LOAN_FORM_ENTRY,
  GET_ALL_PROPERTIES,
  REMOVE_LOAN_ENTRY,
  REMOVE_LOAN_FORM_ENTRY,
  RESET_LOAN_FORM,
  SET_SELECTED_ADD_LOAN_PROPERTY,
  SET_SELECTED_CAPITAL_BASE_PROPERTY,
  UPDATE_LOAN_ENTRY,
  UPDATE_NEW_PROPERTY,
} from "./types";
import moment from "moment";
import { dbRef } from "../firebase";
import { parseAddressData, snapshotToArray } from "../utils/helpers";

export const getAllProperties = (uid) => async (dispatch) => {
  console.log("Getting all properties", uid);
  try {
    let val = [];
    const snapshot = await dbRef.child(`properties/${uid}`).once("value");
    if (snapshot.val()) {
      val = snapshotToArray(snapshot.val());
    }
    dispatch({ type: GET_ALL_PROPERTIES, payload: val });
    return val;
  } catch (err) {
    console.log(err);
  }
};

export const addNewProperty = (newProperty, uid) => async (dispatch) => {
  const propertyId = newProperty.id;
  delete newProperty.id;
  try {
    console.log("Adding new property", newProperty, uid);
    await dbRef.child(`properties/${uid}/${propertyId}`).set(newProperty);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addNewSampleProperty = async (newProperty, uid) => {
  const propertyId = newProperty.id;
  delete newProperty.id;
  try {
    console.log("Adding new property", newProperty, uid);
    await dbRef.child(`properties/${uid}/${propertyId}`).set(newProperty);
  } catch (err) {
    console.log(err);
    return err;
  }
};
// Update property
export const updatePropertyInformation = (propertyData, uid) => async (
  dispatch
) => {
  const propertyId = propertyData.id;
  delete propertyData.id;
  try {
    await dbRef.child(`properties/${uid}/${propertyId}`).set(propertyData);
  } catch (err) {
    console.log(err);
    return err;
  }
};

// Delete property
export const deleteProperty = (propertyId, uid) => async (dispatch) => {
  console.log("Deleting property:", propertyId);
  try {
    await dbRef.child(`properties/${uid}/${propertyId}`).set(null);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getValuation = ({
  streetAddress,
  suburb,
  state,
  postCode,
}) => async (dispatch) => {
  let url = `https://www.domain.com.au/property-profile/${parseAddressData(
    streetAddress,
    suburb,
    state,
    postCode
  )}`;
  fetch(url).then((res) => console.log(res));
  console.log(url);
};

export const setSelectedCapitalBaseProperty = (propertyId) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CAPITAL_BASE_PROPERTY,
    payload: propertyId,
  });
};

export const setSelectedAddLoanProperty = (propertyId) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ADD_LOAN_PROPERTY,
    payload: propertyId,
  });
};

export const updateNewProperty = (field) => (dispatch) => {
  dispatch({
    type: UPDATE_NEW_PROPERTY,
    payload: field,
  });
};

export const addLoanEntry = (entry, id) => (dispatch) => {
  if (entry === null) {
    entry = {
      loanTerm: 0,
      loanStartDate: moment().format("YYYY-MM-DD"),
      loanAmount: 0,
      interestRate: 0,
      interestOnly: false,
    };
  }
  entry["id"] = id;
  dispatch({
    type: ADD_LOAN_ENTRY,
    payload: entry,
  });
};

export const removeLoanEntry = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_LOAN_ENTRY,
    id: id,
  });
};

export const addLoanFormEntry = (entry, id) => (dispatch) => {
  dispatch({
    type: ADD_LOAN_FORM_ENTRY,
    id: id,
    payload: entry,
  });
};

export const removeLoanFormEntry = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_LOAN_FORM_ENTRY,
    id: id,
  });
};

export const resetLoanForm = () => (dispatch) => {
  dispatch({
    type: RESET_LOAN_FORM,
  });
};

export const updateLoanEntry = (field, id) => (dispatch) => {
  dispatch({
    type: UPDATE_LOAN_ENTRY,
    id: id,
    payload: field,
  });
};

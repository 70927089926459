import {CREATE_SESSION, DESTROY_SESSION, GET_USER_DETAILS} from "../actions/types";

const initialState = {
  user: null,
  isAuthenticated: false,
  userDetails: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SESSION:
      localStorage.setItem("authUser", "loggedIn");
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };
    case DESTROY_SESSION:
      localStorage.removeItem("authUser");
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      };
    default:
      return state;
  }
}

export default authReducer;

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { numberFormatter } from "../../utils/helpers";
import { ledgerDropdownList } from "../../utils/ledger";

const states = ["NSW", "QLD", "SA", "TAS", "VIC", "WA", "ACT", "NT"];

const parseReadonlyValue = (value, numType, type) => {
  if (type === "date") {
    return moment(value).format("DD-MMM-YY");
  } else if (isNaN(value) || type === "text") {
    return value;
  } else if (numType === "percent") {
    let val = value < 1 ? value : value / 100;
    return Number(val).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
  } else if (numType === "years") {
    return `${value} years`;
  } else {
    return numberFormatter.format(value);
  }
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const renderField = ({
  label,
  readonly,
  placeholder,
  type,
  prepend,
  append,
  numType,
  tooltip,
  required,
  input,
  val,
  dense,
  meta: { touched, error },
}) => {
  let classNames = "";
  let readOnlyValue =
    input && input.value !== "" ? parseReadonlyValue(input.value, numType, type) : "";
  if (val) {
    readOnlyValue = parseReadonlyValue(val, numType, type);
  }
  if (append) {
    classNames += "appended ";
  }
  if (prepend) {
    classNames += "prepended ";
  }

  let formControl = (
    <Form.Control
      {...input}
      placeholder={type === "date" ? "dd/mm/yyyy" : placeholder}
      type={type}
      step={0.01}
      className={classNames}
    />
  );
  return (
    <Form.Group style={{ marginBottom: dense ? "0" : "13px" }}>
      {label && (
        <InputLabel style={{ height: "16px" }}>
          {label}
          {tooltip && (
            <Tooltip title={tooltip} aria-label={tooltip}>
              <i
                style={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                className="material-icons-outlined"
              >
                info
              </i>
            </Tooltip>
          )}
          {required && (
            <span className="error" style={{ marginLeft: "5px" }}>
              *
            </span>
          )}
        </InputLabel>
      )}
      <InputGroup>
        {prepend && !readonly && (
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        {readonly && <p>{readOnlyValue}</p>}
        {!readonly && formControl}
        {append && !readonly && (
          <InputGroup.Append>
            <InputGroup.Text>{append}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      {touched && error && <span className="error">{error}</span>}
    </Form.Group>
  );
};

export const renderSwitch = ({ label, readonly, helpText, input, meta: { touched, error } }) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">
      {label}
      <Tooltip title={helpText} aria-label={helpText}>
        <i
          style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
          className="material-icons-outlined"
        >
          info
        </i>
      </Tooltip>
    </FormLabel>
    <FormGroup>
      {readonly && <p>{input && input.value === true ? "Yes" : "No"}</p>}
      {!readonly && (
        <FormControlLabel
          control={<Switch checked={!!input.value} color="primary" {...input} />}
          label={""}
        />
      )}
      {touched && error && <span className="error">{error}</span>}
    </FormGroup>
  </FormControl>
);

export const renderCheckbox = ({ label, readonly, input, md, meta: { touched, error } }) => (
  <Form.Group as={Col} md={md}>
    <Form.Label>{label}</Form.Label>
    {readonly && <p>{input && input.value === true ? "Yes" : "No"}</p>}
    {!readonly && (
      <Form.Check
        custom
        {...input}
        checked={input.value}
        id={input.name}
        label={""}
        type={"checkbox"}
      />
    )}
    {touched && error && <span className="error">{error}</span>}
  </Form.Group>
);

export const renderTextField = ({ label, type, prepend, append, input, num }) => (
  <Form.Group as={Col}>
    <Form.Label>{label}</Form.Label>
    <InputGroup>
      {prepend && (
        <InputGroup.Prepend>
          <InputGroup.Text>{prepend}</InputGroup.Text>
        </InputGroup.Prepend>
      )}
      <Form.Control {...input} value={num} readOnly type={type} />
      {append && (
        <InputGroup.Append>
          <InputGroup.Text>{append}</InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>
  </Form.Group>
);

export const renderTextArea = ({ label, input, placeholder, rows, meta: { touched, error } }) => (
  <Form.Group as={Col}>
    <Form.Label>{label}</Form.Label>
    <InputGroup>
      <Form.Control {...input} placeholder={placeholder} as="textarea" rows={rows} />
    </InputGroup>
    {touched && error && <span className="error">{error}</span>}
  </Form.Group>
);

export const renderStateSelection = ({
  label,
  input,
  tooltip,
  required,
  readonly,
  meta: { touched, error },
}) => {
  return (
    <Form.Group>
      <InputLabel style={{ height: "16px" }}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} aria-label={tooltip}>
            <i
              style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
              className="material-icons-outlined"
            >
              info
            </i>
          </Tooltip>
        )}
        {required && (
          <span className="error" style={{ marginLeft: "5px" }}>
            *
          </span>
        )}
      </InputLabel>
      {readonly && <p>{input ? input.value : ""}</p>}
      {!readonly && (
        <Form.Control as="select" {...input}>
          <option value="">-- Select state --</option>
          {states.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Form.Control>
      )}
      {touched && error && <span className="error">{error}</span>}
    </Form.Group>
  );
};

export const renderEntryTypeOptionSelection = ({
  label,
  input,
  tooltip,
  required,
  loans,
  meta: { touched, error },
}) => {
  return (
    <Form.Group>
      <InputLabel style={{ height: "16px" }}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} aria-label={tooltip}>
            <i
              style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
              className="material-icons-outlined"
            >
              info
            </i>
          </Tooltip>
        )}
        {required && (
          <span className="error" style={{ marginLeft: "5px" }}>
            *
          </span>
        )}
      </InputLabel>
      <InputGroup>
        <Form.Control as="select" {...input}>
          {renderEntryTypeOptions(loans)}
        </Form.Control>
      </InputGroup>
      {touched && error && <span className="error">{error}</span>}
    </Form.Group>
  );
};

export const renderEntryTypeOptions = (loans) => {
  return ledgerDropdownList.map((item) => {
    let option = null;
    if (item.key === "loanRepayment") {
      if (loans) {
        option = loans.map((loan, i) => {
          return (
            <option key={item.name + i} value={`${item.key}_${loan.id}`}>
              {item.name} - {loan.loanName}
            </option>
          );
        });
      }
    } else if (item.key === "empty") {
      option = (
        <option key={item.key} value disabled>
          -------
        </option>
      );
    } else {
      option = (
        <option key={item.name} value={item.key}>
          {item.name}
        </option>
      );
    }
    return option;
  });
};

export const renderDepreciationTypeOptionSelection = ({
  label,
  input,
  tooltip,
  required,
  meta: { touched, error },
}) => {
  return (
    <Form.Group>
      <InputLabel style={{ height: "16px" }}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} aria-label={tooltip}>
            <i
              style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
              className="material-icons-outlined"
            >
              info
            </i>
          </Tooltip>
        )}
        {required && (
          <span className="error" style={{ marginLeft: "5px" }}>
            *
          </span>
        )}
      </InputLabel>
      <InputGroup>
        <Form.Control as="select" {...input}>
          <option value="">-- Select type of depreciation --</option>
          <option value="none">No depreciation</option>
          <option value="straightLine">Prime cost (straight line)</option>
        </Form.Control>
      </InputGroup>
      {touched && error && <span className="error">{error}</span>}
    </Form.Group>
  );
};

export const renderDepreciationScheduleTypeOptionSelection = ({
  label,
  input,
  tooltip,
  required,
  meta: { touched, error },
}) => {
  return (
    <Form.Group>
      <InputLabel style={{ height: "16px" }}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} aria-label={tooltip}>
            <i
              style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
              className="material-icons-outlined"
            >
              info
            </i>
          </Tooltip>
        )}
        {required && (
          <span className="error" style={{ marginLeft: "5px" }}>
            *
          </span>
        )}
      </InputLabel>
      <InputGroup>
        <Form.Control as="select" {...input}>
          <option value="">-- Select type of depreciation schedule --</option>
          <option value="plantEquipment">Plant and equipment</option>
          <option value="building">Building</option>
        </Form.Control>
      </InputGroup>
      {touched && error && <span className="error">{error}</span>}
    </Form.Group>
  );
};

export const renderPropertySelection = ({
  label,
  input,
  tooltip,
  required,
  options,
  meta: { touched, error },
}) => (
  <Form.Group>
    <InputLabel style={{ height: "16px" }}>
      {label}
      {tooltip && (
        <Tooltip title={tooltip} aria-label={tooltip}>
          <i
            style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
            className="material-icons-outlined"
          >
            info
          </i>
        </Tooltip>
      )}
      {required && (
        <span className="error" style={{ marginLeft: "5px" }}>
          *
        </span>
      )}
    </InputLabel>
    <InputGroup>
      <Form.Control as="select" {...input}>
        <option value="">-- Select property --</option>
        {options.map((property) => (
          <option key={property.id} value={property.id}>
            {property.propertyName}
          </option>
        ))}
      </Form.Control>
    </InputGroup>
    {touched && error && <span className="error">{error}</span>}
  </Form.Group>
);

export const renderValuationSelection = ({
  label,
  input,
  tooltip,
  required,
  readonly,
  meta: { touched, error },
}) => (
  <Form.Group>
    <InputLabel style={{ height: "16px" }}>
      {label}
      {tooltip && (
        <Tooltip title={tooltip} aria-label={tooltip}>
          <i
            style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
            className="material-icons-outlined"
          >
            info
          </i>
        </Tooltip>
      )}
      {required && (
        <span className="error" style={{ marginLeft: "5px" }}>
          *
        </span>
      )}
    </InputLabel>
    {readonly && <p>{input ? input.value.charAt(0).toUpperCase() + input.value.slice(1) : ""}</p>}
    {!readonly && (
      <Form.Control as="select" {...input}>
        <option value="formal">Formal</option>
        <option value="informal">Informal</option>
      </Form.Control>
    )}
    {touched && error && <span className="error">{error}</span>}
  </Form.Group>
);

export const renderYearSelection = ({
  label,
  input,
  tooltip,
  required,
  options,
  meta: { touched, error },
}) => (
  <Form.Group>
    <InputLabel style={{ height: "16px" }}>
      {label}
      {tooltip && (
        <Tooltip title={tooltip} aria-label={tooltip}>
          <i
            style={{ marginLeft: "5px", fontSize: "14px", cursor: "pointer" }}
            className="material-icons-outlined"
          >
            info
          </i>
        </Tooltip>
      )}
      {required && (
        <span className="error" style={{ marginLeft: "5px" }}>
          *
        </span>
      )}
    </InputLabel>
    <Form.Control as="select" {...input}>
      <option value="">-- Select financial year --</option>
      {options.map((year) => (
        <option key={year.value} value={year.value}>
          {year.label}
        </option>
      ))}
    </Form.Control>
    {touched && error && <span className="error">{error}</span>}
  </Form.Group>
);

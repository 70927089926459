import React from "react";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { render } from "react-dom";
import { hotjar } from "react-hotjar";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

const rootElement = document.getElementById("root");
render(<App />, rootElement);

hotjar.initialize(2073224, 6);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

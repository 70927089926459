import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getUserDetails, signOut } from "../../actions/authActions";
import { getAllCashflows } from "../../actions/cashflowActions";
import { setIsLoading, setSidebarToggle } from "../../actions/layoutActions";
import { getAllLedgerEntries } from "../../actions/ledgerActions";
import { getAllProperties } from "../../actions/propertyActions";
import Sidebar from "../layout/Sidebar";

const PrivateRoute = ({
  component: Component,
  auth,
  user,
  ledgerEntries,
  getAllLedgerEntries,
  properties,
  getAllProperties,
  cashflows,
  getAllCashflows,
  getUserDetails,
  setIsLoading,
  isAdmin,
  ...rest
}) => {
  // Fetch data from firebase
  useEffect(() => {
    if (typeof auth.user === "object" && auth.user !== null) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const [entriesP, propertiesP, userP] = await Promise.all([
            getAllLedgerEntries(auth.user.uid),
            getAllProperties(auth.user.uid),
            getUserDetails(auth.user.uid),
          ]);
          if (cashflows.length === 0 || cashflows.length !== propertiesP.length) {
            await getAllCashflows(propertiesP, entriesP, userP);
          }
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [auth.user, isAdmin]);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true ? (
          <div>
            <div className="wrapper">
              <Sidebar isAdmin={isAdmin} />
              <Container fluid className="main-content">
                <Component Ad {...props} {...rest} />
              </Container>
            </div>
          </div>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllProperties: PropTypes.func.isRequired,
  getAllLedgerEntries: PropTypes.func.isRequired,
  getAllCashflows: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  properties: _.orderBy(state.properties.allProperties, ["sale"], ["desc"]),
  ledgerEntries: state.ledgerEntries.ledgerEntries,
  cashflows: state.cashflows.allCashflows,
  user: state.auth.userDetails,
  isLoading: state.layout.isLoading,
});

export default connect(mapStateToProps, {
  setSidebarToggle,
  setIsLoading,
  getAllProperties,
  getAllLedgerEntries,
  getAllCashflows,
  getUserDetails,
  signOut,
})(PrivateRoute);

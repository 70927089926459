import {SET_IS_LOADING, SET_SIDEBAR_TOGGLE} from "../actions/types";

const initialState = {
  sidebarToggle: false,
  isLoading: true,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: !state.sidebarToggle
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
}

export default layoutReducer;

export const GET_ERRORS = "GET_ERRORS";
export const GET_ALL_PROPERTIES = "GET_ALL_PROPERTIES";
export const GET_MONTHLY_CASHFLOW = "GET_MONTHLY_CASHFLOW";
export const GET_ALL_CASHFLOWS = "GET_ALL_CASHFLOWS";
export const UPDATE_CASHFLOWS = "UPDATE_CASHFLOWS";
export const ADD_CASHFLOW = "ADD_CASHFLOW";
export const SET_RISK_MANAGEMENT_CASHFLOWS = "SET_RISK_MANAGEMENT_CASHFLOWS";
export const SET_UPDATED_RISK_MANAGEMENT_CASHFLOWS = "SET_UPDATED_RISK_MANAGEMENT_CASHFLOWS";
export const SET_OVERVIEW_DASHBOARD_PROPERTY = "SET_OVERVIEW_DASHBOARD_PROPERTY";
export const SET_OVERVIEW_DASHBOARD_DATE_FORMAT = "SET_OVERVIEW_DASHBOARD_DATE_FORMAT";
export const SET_DETAILED_DASHBOARD_PROPERTY = "SET_DETAILED_DASHBOARD_PROPERTY";
export const SET_DETAILED_DASHBOARD_DATE_FORMAT = "SET_DETAILED_DASHBOARD_DATE_FORMAT";
export const SET_DETAILED_DASHBOARD_TYPE = "SET_DETAILED_DASHBOARD_TYPE";
export const SET_RISK_MANAGEMENT_INTEREST_RATES = "SET_RISK_MANAGEMENT_INTEREST_RATES";
export const SET_RISK_MANAGEMENT_VACANCIES = "SET_RISK_MANAGEMENT_VACANCIES";
export const GET_ALL_LEDGER_ENTRIES = "GET_ALL_LEDGER_ENTRIES";
export const SET_SAVING_LEDGER_ENTRIES = "SET_SAVING_LEDGER_ENTRIES";
export const RESET_LEDGER_ENTRIES = "RESET_LEDGER_ENTRIES";
export const SET_SELECTED_CAPITAL_BASE_PROPERTY = "SET_SELECTED_CAPITAL_BASE_PROPERTY";
export const SET_SELECTED_ADD_LOAN_PROPERTY = "SET_SELECTED_ADD_LOAN_PROPERTY";
export const UPDATE_NEW_PROPERTY = "UPDATE_NEW_PROPERTY";
export const ADD_LOAN_FORM_ENTRY = "ADD_LOAN_FORM_ENTRY";
export const REMOVE_LOAN_FORM_ENTRY = "REMOVE_LOAN_FORM_ENTRY";
export const ADD_LOAN_ENTRY = "ADD_LOAN_ENTRY";
export const REMOVE_LOAN_ENTRY = "REMOVE_LOAN_ENTRY";
export const RESET_LOAN_FORM = "RESET_LOAN_FORM";
export const UPDATE_LOAN_ENTRY = "UPDATE_LOAN_ENTRY";
export const SET_SIDEBAR_TOGGLE = "SET_SIDEBAR_TOGGLE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const CREATE_SESSION = "CREATE_SESSION";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const RESET_APP = "RESET_APP";
export const GET_USER_DETAILS = "GET_USER_DETAILS";

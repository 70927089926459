import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.blue[800],
    main: colors.blue[600],
    light: colors.blue[300],
  },
  secondary: {
    contrastText: white,
    dark: colors.pink[900],
    main: colors.pink[500],
    light: colors.pink[100],
  },
  success: {
    contrastText: white,
    dark: colors.green[800],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[800],
    main: colors.blue[400],
    light: colors.blue[200],
    lighter: colors.blue[100],
    lightest: colors.blue[50],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[300],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};

export default palette;

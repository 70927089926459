import {
  ADD_LOAN_ENTRY,
  ADD_LOAN_FORM_ENTRY,
  GET_ALL_PROPERTIES,
  REMOVE_LOAN_ENTRY,
  REMOVE_LOAN_FORM_ENTRY,
  RESET_LOAN_FORM,
  SET_SELECTED_ADD_LOAN_PROPERTY,
  SET_SELECTED_CAPITAL_BASE_PROPERTY,
  UPDATE_LOAN_ENTRY,
  UPDATE_NEW_PROPERTY,
  UPDATE_PROPERTY
} from "../actions/types";
import _ from "lodash";
import moment from "moment";

const example = {
  "propertyName": "4/7 Marlow Street",
  "purchaseDate": "2018-04-24",
  "purchasePrice": 167100,
  "rentalIncome": 235,
  "councilRates": 750,
  "waterRates": 0,
  "insurancePremium": 0,
  "managementFees": 7,
  "maintenanceExpense": 1,
  "strata": 400,
  "legal": 1000,
  "stampDuty": 5000,
};

const initialState = {
  allProperties: [],
  selectedCapitalBaseProperty: "",
  setSelectedAddLoanProperty: "",
  newProperty: {
    propertyName: "",
    purchaseDate: moment().format("YYYY-MM-DD"),
    purchasePrice: 0,
    rentalIncome: 0,
    councilRates: 0,
    waterRates: 0,
    insurancePremium: 0,
    managementFees: 0,
    maintenanceExpense: 0,
    strata: 0,
    legal: 0,
    stampDuty: 0,
  },
  loanEntries: [],
  loanFormEntries: []
};

initialState.newProperty = example;

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PROPERTIES:
      return {
        ...state,
        allProperties: action.payload
      };
    case UPDATE_PROPERTY: {
      return Object.assign({}, state, {
        allProperties: state.allProperties.map(property => {
          if (property.id === action.propertyId) {
            return Object.assign({}, property, action.payload);
          }
          return property;
        })
      });
    }
    case SET_SELECTED_CAPITAL_BASE_PROPERTY:
      return {
        ...state,
        selectedCapitalBaseProperty: action.payload
      };
    case SET_SELECTED_ADD_LOAN_PROPERTY:
      return {
        ...state,
        selectedAddLoanProperty: action.payload
      };
    case UPDATE_NEW_PROPERTY:
      state.newProperty[action.payload.name] = isNaN(action.payload.value) ? action.payload.value : parseFloat(action.payload.value);
      return {
        ...state,
        newProperty: state.newProperty
      };
    case ADD_LOAN_FORM_ENTRY:
      return {
        ...state,
        loanFormEntries: _.union(state.loanFormEntries, [{id: action.id, element: action.payload}])
      };
    case ADD_LOAN_ENTRY:
      return {
        ...state,
        loanEntries: _.union(state.loanEntries, [action.payload])
      };
    case REMOVE_LOAN_FORM_ENTRY:
      return {
        ...state,
        loanFormEntries: state.loanFormEntries.filter(elem => elem.id !== action.id)
      };
    case REMOVE_LOAN_ENTRY:
      return {
        ...state,
        loanEntries: state.loanEntries.filter(elem => elem.id !== action.id)
      };
    case RESET_LOAN_FORM:
      return {
        ...state,
        loanEntries: [],
        loanFormEntries: []
      };
    case UPDATE_LOAN_ENTRY:
      const field = action.payload;
      let entryIndex = state.loanEntries.findIndex(elem => elem.id === action.id);
      state.loanEntries[entryIndex][field.name] = isNaN(field.value) ? field.value : parseFloat(field.value);
      return {
        ...state,
        loanEntries: state.loanEntries
      };
    default:
      return state;
  }
};

export default propertyReducer;

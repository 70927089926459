import { numberFormatter } from "../../utils/helpers";
import moment from "moment";

export const required = (value) =>
  value || value === 0 ? undefined : `Required`;
export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const minValueYears = (min) => (value) =>
  value && value < min ? `Must be at least ${min} years` : undefined;
export const minValue0 = minValue(0);
export const minValueNeg1Mil = minValue(-1000000);
export const minValueNeg10Mil = minValue(-10000000);
export const minValueNeg100Mil = minValue(-100000000);
export const minValue1 = minValueYears(1);
export const maxValuePercent = (max) => (value) =>
  value && value > max ? `Must be less than ${max}%` : undefined;
export const maxValueAmount = (max) => (value) =>
  value && value > max
    ? `Must be less than ${numberFormatter.format(max)}`
    : undefined;
export const maxValueYears = (max) => (value) =>
  value && value > max ? `Must be less than ${max} years` : undefined;
export const dateBetween = (value, min, max) =>
  value && !moment(value).isBetween(moment(min), moment(max))
    ? `Date must be between ${moment(min).format("DD-MMM-YYYY")} and ${moment(
        max
      ).format("DD-MMM-YYYY")}`
    : undefined;
export const dateAfter = (value) =>
  value && !moment(value).isBefore(moment())
    ? `Date must be before ${moment().format("DD-MMM-YYYY")}`
    : undefined;
export const maxValue10000 = maxValueAmount(10000);
export const maxValue30000 = maxValueAmount(30000);
export const maxValue50000 = maxValueAmount(50000);
export const maxValue200000 = maxValueAmount(200000);
export const maxValue1mil = maxValueAmount(1000000);
export const maxValue10mil = maxValueAmount(10000000);
export const maxValue100mil = maxValueAmount(100000000);
export const maxValue20 = maxValuePercent(20);
export const maxValue10 = maxValuePercent(10);
export const maxValue40P = maxValuePercent(40);
export const maxValue40 = maxValueYears(40);
export const maxValue100 = maxValueYears(100);

import React, {useState} from "react";
import {connect} from "react-redux";
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {forgotPassword} from "../../actions/authActions";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";

const ForgotPassword = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const onSubmit = async values => {
        try {
            setLoading(true);
            await props.forgotPassword(values.email, props.history);
        } catch (err) {
            if (err.code.includes("user-not-found")) {
                setError("The entered email does not exist");
            } else {
                setError(err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Container maxWidth="xs" style={{height: "100vh"}}>
                <div style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Avatar style={{
                        backgroundColor: "#2962FF",
                        margin: "8px"
                    }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography variant="h4">
                        Forgot your password?
                    </Typography>
                    <ForgotPasswordForm onSubmit={onSubmit} isLoading={isLoading} err={error} />
                </div>
            </Container>
        </div>
    )
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {forgotPassword})(ForgotPassword);

import palette from "../palette";

const isSuccess = (style) => (props) =>
  props.color === "success" && props.variant === "contained" ? style : {};

const Button = {
  root: {
    color: isSuccess(palette.success.contrastText),
    backgroundColor: isSuccess(palette.success.main),
    "&:hover": {
      backgroundColor: isSuccess(palette.success.dark),
    },
  },
  contained: {
    boxShadow: "0 0 14px 0 rgba(53,64,82,.05)",
  },
}

export default Button;

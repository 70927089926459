import React, { useState } from "react";
import { connect } from "react-redux";
import { signOut, signUpWithSampleProperty } from "../../actions/authActions";
import { SubmissionError } from "redux-form";

import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import SignUpForm from "../forms/SignUpForm";

const SignUp = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      throw new SubmissionError({
        password: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
    } else {
      try {
        setLoading(true);
        await props.signUpWithSampleProperty(
          values.email,
          values.password,
          values.fullName,
          props.history
        );
      } catch (err) {
        console.log(err);
        if (err.code.includes("email-already-in-use")) {
          setError("The entered email is already in use");
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Container maxWidth="xs" style={{ height: "100vh" }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            style={{
              backgroundColor: "#2962FF",
              margin: "8px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h4">Sign up</Typography>
          <SignUpForm onSubmit={onSubmit} isLoading={isLoading} err={error} />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { signUpWithSampleProperty, signOut })(
  SignUp
);

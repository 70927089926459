import {
  ADD_CASHFLOW,
  GET_ALL_CASHFLOWS,
  GET_MONTHLY_CASHFLOW,
  SET_RISK_MANAGEMENT_CASHFLOWS,
  SET_UPDATED_RISK_MANAGEMENT_CASHFLOWS,
  UPDATE_CASHFLOWS
} from "../actions/types";

const initialState = {
  allCashflows: [],
  monthlyCashflows: [],
  riskManagementCashflows: [],
  updatedRiskManagementCashflows: []
};

const cashflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MONTHLY_CASHFLOW:
      return {
        ...state,
        monthlyCashflows: action.payload
      };
    case GET_ALL_CASHFLOWS:
      return {
        ...state,
        allCashflows: action.payload
      };
    case ADD_CASHFLOW:
      return {
        ...state,
        allCashflows: [...state.allCashflows, action.payload]
      };
    case UPDATE_CASHFLOWS:
      return Object.assign({}, state, {
        allCashflows: state.allCashflows.map(cf => {
          if (cf.propertyId === action.payload.propertyId) {
            return Object.assign(cf, action.payload);
          }
          return cf;
        })
      });
    case SET_RISK_MANAGEMENT_CASHFLOWS:
      return {
        ...state,
        riskManagementCashflows: action.payloadA,
        updatedRiskManagementCashflows: action.payloadB
      };
    case SET_UPDATED_RISK_MANAGEMENT_CASHFLOWS:
      return {
        ...state,
        updatedRiskManagementCashflows: action.payload
      };
    default:
      return state;
  }
}

export default cashflowReducer
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {rootReducer} from "./reducers";
import {composeWithDevTools} from "redux-devtools-extension";

const initialState = {};

const middleware = [thunk];

// TODO remove dev tools when prod
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware)
  )
);

export default store;

import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {renderField} from "./FormHelpers";
import {required} from "./validate";
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Form from "react-bootstrap/Form";

class ForgotPasswordForm extends Component {
    render() {
        const {handleSubmit, isLoading, err} = this.props;
        return (
            <Form onSubmit={handleSubmit} noValidate style={{
                width: "100%",
                marginTop: "8px"
            }}>
                <Field
                    name="email"
                    component={renderField}
                    type="text"
                    label="Email"
                    validate={[ required ]}
                />
                <span className={"error"}>{err}</span>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                        margin: "24px 0 16px"
                    }}
                >
                    {!isLoading &&
                    <span>Submit</span>
                    }
                    {isLoading &&
                    <span className="spinner-border" style={{height: "24px", width: "24px"}} role="status" aria-hidden="true"></span>
                    }
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="/login" variant="body2">
                            {"Already have an account? Log in"}
                        </Link>
                    </Grid>
                    <Grid item xs style={{textAlign: "right"}}>
                        <Link href="/sign-up" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid>
            </Form>
        )
    }
}

ForgotPasswordForm = reduxForm({
    form: 'ForgotPassword',
})(ForgotPasswordForm);

export default ForgotPasswordForm;
import _ from "lodash";
import moment from "moment";
import {
  convertToFinancialYear,
  entryTypes,
  getClassification,
  getEntryId,
  getEntryName,
} from "./helpers";

export const sumAllKeys = (obj) => {
  return Object.keys(obj).reduce((sum, key) => {
    if (Array.isArray(obj[key])) {
      return sum + obj[key].reduce((a, b) => a + b, 0);
    } else {
      return sum + parseFloat(obj[key] || 0);
    }
  }, 0);
};

export const recalculateData = (cf, prevBalanceCashflows) => {
  cf.totalIncome = sumAllKeys(cf.inflows);
  cf.totalExpenses = sumAllKeys(cf.outflows);
  cf.netCashflow = cf.totalIncome + cf.totalExpenses;
  cf.netCashflowPostDep =
    cf.netCashflow - cf.outflows.capitalWorks - cf.depreciationAndAmortisedSettlementFees;
  cf.netCashflowPostPrincipal = cf.netCashflow + cf.principalRepayments.reduce((a, b) => a + b, 0);
  cf.balanceCashflows = prevBalanceCashflows.map(
    (loan, index) => parseFloat(loan) + cf.principalRepayments[index]
  );
  return cf;
};

export const interestPayment = (rate, pv) => {
  return -(pv * (rate / 12));
};

export const principalPayment = (rate, per, pv) => {
  return -(monthlyPayment(rate, per, pv) + interestPayment(rate, pv));
};

export const monthlyPayment = (rate, per, pv) => {
  const r = rate / 12;
  const factor = Math.pow(1 + r, per);
  return (pv * (r * factor)) / (factor - 1);
};

export const calculateInterestExpenses = (loans, rate, currentDate, prevBalanceCashflows) => {
  return loans.map((loan, index) => {
    if (rate === null) {
      rate = loan.interestRate;
    } else {
      rate += loan.interestRate;
    }
    if (moment(currentDate).isSameOrAfter(loan.loanStartDate, "month")) {
      // console.log(moment(currentDate).format("DD-MMM-YYYY"), loan.loanStartDate);
      return interestPayment(rate / 100, prevBalanceCashflows[index]);
    } else {
      return 0;
    }
  });
};

export const calculatePrincipalRepayments = (loans, rate, currentDate, prevBalanceCashflows) => {
  return loans.map((loan, index) => {
    // Interest only loan
    if (loan.interestOnly === true) {
      return 0;
    }
    if (rate === null) {
      rate = loan.interestRate;
    } else {
      rate += loan.interestRate;
    }
    const totalLoanMonths = loan.loanTerm * 12;
    const monthsSinceLoanStart = Math.abs(
      moment(loan.loanStartDate).diff(moment(currentDate), "months")
    );
    if (
      moment(currentDate).isSameOrAfter(loan.loanStartDate, "month") &&
      monthsSinceLoanStart < totalLoanMonths
    ) {
      return principalPayment(
        rate / 100,
        totalLoanMonths - monthsSinceLoanStart,
        prevBalanceCashflows[index]
      );
    } else {
      return 0;
    }
  });
};

export const calculateRentalIncome = (income, dilution, escalationFactor) => {
  return income * (52 / 12) * dilution * escalationFactor;
};

// TODO store escalation factors in user/property
export const generateCashflows = (property, user) => {
  const { dilutionFactor, inflationRate, capitalGrowthRate } = user;
  if (!property.loans) {
    Object.assign(property, { loans: [] });
  }
  let cashflows = [];
  // Set previous values
  let prevEscalationFactor = 1;
  let prevCapitalGrowthFactor = 1;
  let prevBalanceCashflows = new Array(property.loans.length).fill(0);
  let currentDate = moment(property.purchaseDate);
  // Get earliest valuation
  let previousValuation = _.orderBy(property.valuations, ["date"], ["asc"])[0];
  const endDate = moment(property.purchaseDate).add(50, "years");
  const monthOffset = moment(currentDate).diff(moment([moment(currentDate).year(), 7]), "months");
  // Generate cashflows for loan term + 15 years
  const monthsToGenerate = Math.abs(
    moment(property.purchaseDate).diff(endDate, "months") + monthOffset
  );
  const sortedValuations = _.orderBy(property.valuations, ["date"], ["desc"]);
  for (let i = 1; i <= monthsToGenerate; i++) {
    // Determine current factors
    let currentMonth = currentDate.format("YYYY-MM");
    let currentFY = convertToFinancialYear(currentDate);
    let escalationFlag = i % 12 === 0 ? 1 : 0;
    let escalationFactor = currentDate.isSameOrAfter(moment(), "months")
      ? prevEscalationFactor * (1 + (escalationFlag * inflationRate) / 100)
      : 1;
    let capitalGrowthFactor =
      i === 1
        ? prevCapitalGrowthFactor
        : prevCapitalGrowthFactor * (1 + capitalGrowthRate / 100 / 12);
    // Initialise previous balances
    prevBalanceCashflows.map((cashflow, index) => {
      const currentLoan = property.loans[index];
      if (moment(currentDate).isSame(currentLoan.loanStartDate, "months")) {
        return currentLoan.loanAmount;
      }
      return cashflow;
    });
    // Calculate rental income
    let currentRentalIncome = calculateRentalIncome(
      property.rentalIncome,
      dilutionFactor / 100,
      escalationFactor
    );

    // Calculate interest expenses
    let interestExpenses = calculateInterestExpenses(
      property.loans,
      null,
      currentDate,
      prevBalanceCashflows
    );

    // Calculate principal repayments
    let principalRepayments = calculatePrincipalRepayments(
      property.loans,
      null,
      currentDate,
      prevBalanceCashflows
    );

    let currentValuation = sortedValuations.find((valuation) =>
      moment(currentDate).isSameOrAfter(valuation.date, "month")
    );
    if (previousValuation !== currentValuation) {
      capitalGrowthFactor = 1;
    }

    // Create cashflow
    let cf = {
      date: currentDate.format("YYYY-MM-DD"),
      month: currentMonth,
      financialYear: currentFY,
      inflows: {
        rentalIncome: currentRentalIncome,
      },
      outflows: {
        interestExpenses: interestExpenses,
        capitalWorks: 0,
        otherExpenses: 0,
        councilRates: -(property.councilRates * escalationFactor) / 12,
        waterRates: -(property.waterRates * escalationFactor) / 12,
        managementFees: -((property.managementFees / 100) * currentRentalIncome),
        insurancePremium: -(property.insurancePremium * escalationFactor) / 12,
        maintenanceExpense: -((property.maintenanceExpense / 100) * currentRentalIncome),
        strata: -(property.strata * escalationFactor) / 3,
      },
      valuation: currentValuation.amount * capitalGrowthFactor,
      principalRepayments: principalRepayments,
      depreciationAndAmortisedSettlementFees: 0,
      other: {
        escalationFactor: escalationFactor,
        capitalGrowthFactor: capitalGrowthFactor,
        escalationFlag: escalationFlag,
      },
    };

    // Fill in rest of data (i.e. net cashflow)
    cf = recalculateData(cf, prevBalanceCashflows);

    // Iterate
    currentDate.add(1, "month");
    prevEscalationFactor = escalationFactor;
    prevCapitalGrowthFactor = capitalGrowthFactor;
    prevBalanceCashflows = cf["balanceCashflows"];
    previousValuation = currentValuation;
    cashflows.push(cf);
  }
  return cashflows;
};

export const regenerateCashflows = (
  properties,
  originalCashflows,
  cashflows,
  vacancy,
  interestRateIncrease,
  user
) => {
  const { dilutionFactor } = user;
  return cashflows.map((cf, index) => {
    const property = properties.find((p) => p.id === cf.propertyId);
    // TODO Fix this to be actual previous cashflows
    let prevBalanceCashflows = cf.cashflows[0].balanceCashflows;
    cf.cashflows.forEach((cf, i) => {
      if (i === 0) {
        return;
      }
      if (vacancy) {
        cf.inflows.rentalIncome =
          (originalCashflows[index].cashflows[i].inflows.rentalIncome / (dilutionFactor / 100)) *
          (vacancy / 100);
      }
      cf.outflows.interestExpenses = calculateInterestExpenses(
        property.loans,
        interestRateIncrease,
        cf.date,
        prevBalanceCashflows
      );
      cf.principalRepayments = calculatePrincipalRepayments(
        property.loans,
        interestRateIncrease,
        cf.date,
        prevBalanceCashflows
      );
      cf = recalculateData(cf, prevBalanceCashflows);
      prevBalanceCashflows = cf.balanceCashflows;
    });
    return cf;
  });
};

export const generateInitialCashflows = (forecastCashflows, loans) => {
  let historicCashflow = initHistoricCashflows(forecastCashflows[0].date, loans);
  historicCashflow = addToHistoricCashflows(
    historicCashflow,
    [],
    { cashflows: forecastCashflows },
    loans
  );
  return combineHistoricAndForecastCashflows(
    historicCashflow,
    { cashflows: forecastCashflows },
    [],
    forecastCashflows[0].date,
    loans
  );
};

export const generateUpdatedCashflows = (cashflows, newEntries, oldEntries, loans) => {
  console.log("Generating updated cashflows");
  const entries = newEntries.concat(oldEntries);
  const pastEntries = entries.filter((entry) =>
    moment(entry.date).isSameOrBefore(moment(), "months")
  );
  const futureEntries = entries.filter((entry) => moment(entry.date).isAfter(moment(), "months"));
  let historicCashflow = initHistoricCashflows(cashflows.cashflows[0].date, loans);
  historicCashflow = addToHistoricCashflows(historicCashflow, pastEntries, cashflows, loans);
  return combineHistoricAndForecastCashflows(
    historicCashflow,
    cashflows,
    futureEntries,
    cashflows.cashflows[0].date,
    loans
  );
};

const combineHistoricAndForecastCashflows = (
  historicCashflows,
  prevForecastCashflows,
  futureEntries,
  startDate,
  loans
) => {
  let forecastCf = JSON.parse(JSON.stringify(prevForecastCashflows));
  // Update all previous forecasts with ledger entries
  historicCashflows.forEach((cf, index) => {
    Object.assign(forecastCf.cashflows[index], cf);
  });

  for (let i = historicCashflows.length; i < prevForecastCashflows.cashflows.length; i++) {
    const currentDate = forecastCf.cashflows[i].date;
    let prevBalanceCashflows = i > 0 ? forecastCf.cashflows[i - 1].balanceCashflows : [];
    if (i === historicCashflows.length && i > 0) {
      // Initialise previous balances
      loans.forEach((loan, index) => {
        if (moment(forecastCf.cashflows[i - 1].date).isSame(loan.loanStartDate, "month")) {
          prevBalanceCashflows[index] = loan.loanAmount;
        }
        if (moment(forecastCf.cashflows[i - 1].date).isSameOrAfter(loan.loanEndDate, "month")) {
          prevBalanceCashflows[index] = 0;
        }
      });
    }

    // re calculate interest expenses
    forecastCf.cashflows[i].outflows.interestExpenses = calculateInterestExpenses(
      loans,
      null,
      currentDate,
      prevBalanceCashflows
    );
    // re calculate principal repayments
    forecastCf.cashflows[i].principalRepayments = calculatePrincipalRepayments(
      loans,
      null,
      currentDate,
      prevBalanceCashflows
    );
    // calculate depreciationAndAmortisedSettlementFees
    let currentLedgerEntries = futureEntries.filter(
      (entry) => entry.month === moment(currentDate).format("YYYY-MM")
    );
    currentLedgerEntries.forEach((entry) => {
      const entryType = getClassification(getEntryName(entry.typeOfEntry));
      forecastCf.cashflows[i] = addCashflowEntry(forecastCf.cashflows[i], entryType, entry, loans);
    });
    // re calculate other data
    forecastCf.cashflows[i] = recalculateData(forecastCf.cashflows[i], prevBalanceCashflows);
  }
  return forecastCf;
};

const addToHistoricCashflows = (historicCashflow, entries, cashflows, loans) => {
  let balanceCashflows =
    historicCashflow.length > 0
      ? historicCashflow[0].balanceCashflows
      : cashflows.initialLoanAmounts;
  historicCashflow.forEach((cf) => {
    balanceCashflows = balanceCashflows.map((balance, i) => {
      if (balance === 0 && cf.balanceCashflows[i] !== 0) {
        return cf.balanceCashflows[i];
      }
      return balance;
    });
    const entriesForCfMonth = entries.filter((entry) => entry.month === cf.month);
    entriesForCfMonth.forEach((entry) => {
      const entryType = getClassification(getEntryName(entry.typeOfEntry));
      cf = addCashflowEntry(cf, entryType, entry, loans);
    });
    cf = recalculateData(cf, balanceCashflows);
    balanceCashflows = cf.balanceCashflows;
  });
  return historicCashflow;
};

const initHistoricCashflows = (startDate, loans) => {
  const endMonth = moment();
  let currentDate = moment(startDate);
  let cfs = [];
  while (currentDate.isSameOrBefore(endMonth, "month")) {
    const currentBalanceCashflows = loans.map((loan) => {
      if (
        moment(loan.loanStartDate).isSameOrBefore(currentDate, "month") &&
        moment(loan.loanEndDate).isAfter(currentDate, "month")
      ) {
        return !loan.active && loan.originalLoanAmount ? loan.originalLoanAmount : loan.loanAmount;
      } else {
        return 0;
      }
    });
    cfs.push({
      month: currentDate.format("YYYY-MM"),
      inflows: {
        rentalIncome: 0,
      },
      totalIncome: 0,
      outflows: {
        interestExpenses: new Array(loans.length).fill(0),
        otherExpenses: 0,
        councilRates: 0,
        waterRates: 0,
        managementFees: 0,
        insurancePremium: 0,
        maintenanceExpense: 0,
        strata: 0,
        capitalWorks: 0,
      },
      totalExpenses: 0,
      netCashflow: 0,
      principalRepayments: new Array(loans.length).fill(0),
      netCashflowPostPrincipal: 0,
      depreciationAndAmortisedSettlementFees: 0,
      netCashflowPostDep: 0,
      balanceCashflows: currentBalanceCashflows,
    });
    currentDate.add(1, "month");
  }
  return cfs;
};

// TODO Handle case where principal/interest expense in the future
const addCashflowEntry = (cashflow, entryType, entry, loans) => {
  switch (entryType) {
    case entryTypes.INFLOWS:
      cashflow[entryType][entry.typeOfEntry] += entry.amount;
      break;
    case entryTypes.OUTFLOWS:
      cashflow[entryType][entry.typeOfEntry] -= entry.amount;
      break;
    case entryTypes.MULTI:
      const entryName = getEntryName(entry.typeOfEntry);
      const entryIndex = loans.findIndex((loan) => loan.id === getEntryId(entry.typeOfEntry));
      if (entryName === "principalRepayment") {
        cashflow["principalRepayments"][entryIndex] -= entry.amount;
      } else if (entryName === "interestExpense") {
        cashflow["outflows"]["interestExpenses"][entryIndex] -= entry.amount;
      }
      break;
    default:
      cashflow[entry.typeOfEntry] += entry.amount;
  }
  return cashflow;
};

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyB9aXR0t-ZEke3d4jM65hjkQwaxrOGIY8c",
  authDomain: "investment-property-tracker.firebaseapp.com",
  databaseURL: "https://investment-property-tracker.firebaseio.com",
  projectId: "investment-property-tracker",
  storageBucket: "investment-property-tracker.appspot.com",
  messagingSenderId: "401513528988",
  appId: "1:401513528988:web:2da06a04b92fb342",
  measurementId: "G-V7W5K8ZD39",
};

firebase.initializeApp(firebaseConfig);

// Database
export const dbRef = firebase.database().ref();

// Auth
export const authRef = firebase.auth();

// Analytics
export const analyticsRef = firebase.analytics();

// Performance
export const perfRef = firebase.performance();

export default firebase;

import {SET_IS_LOADING, SET_SIDEBAR_TOGGLE} from "./types";

export const setSidebarToggle = () => dispatch => {
  dispatch({
    type: SET_SIDEBAR_TOGGLE
  });
};

export const setIsLoading = (toggle) => dispatch => {
  dispatch({
    type: SET_IS_LOADING,
    payload: toggle
  });
};
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { CREATE_SESSION, DESTROY_SESSION } from "./actions/types";
import "./App.scss";
import AdminRoute from "./components/private-route/AdminRoute";
import PrivateRoute from "./components/private-route/PrivateRoute";
import ForgotPassword from "./components/user/ForgotPassword";
import Login from "./components/user/Login";
import SignUp from "./components/user/SignUp";
import { authRef } from "./firebase";
import store from "./store";
import theme from "./theme";

const LoadingComponent = ({ isLoading, error }) => {
  if (isLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <div
          style={{
            textAlign: "center",
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <ClipLoader color={"#2388E4"} />
        </div>
      </div>
    );
  } else if (error) {
    return (
      <div style={{ height: "100vh" }}>
        <div
          style={{
            textAlign: "center",
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <h4>Sorry, there was a problem loading this page. Please try refreshing.</h4>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

// Components
const Dashboard = Loadable({
  loader: () => import("./components/dashboard/Dashboard"),
  loading: LoadingComponent,
});
const AddProperty = Loadable({
  loader: () => import("./components/forms/property/AddProperty"),
  loading: LoadingComponent,
});
const Property = Loadable({
  loader: () => import("./components/property/Property"),
  loading: LoadingComponent,
});
const RiskManagement = Loadable({
  loader: () => import("./components/risk-management/RiskManagement"),
  loading: LoadingComponent,
});
const AddLedgerEntry = Loadable({
  loader: () => import("./components/forms/transactions/AddLedgerEntry"),
  loading: LoadingComponent,
});
const AddDepreciationSchedule = Loadable({
  loader: () => import("./components/forms/AddDepreciationSchedule"),
  loading: LoadingComponent,
});
const Settings = Loadable({
  loader: () => import("./components/user/Settings"),
  loading: LoadingComponent,
});
const Reports = Loadable({
  loader: () => import("./components/reports/Reports"),
  loading: LoadingComponent,
});
const Error = Loadable({
  loader: () => import("./components/error/Error"),
  loading: LoadingComponent,
});
const Admin = Loadable({
  loader: () => import("./components/admin/Admin"),
  loading: LoadingComponent,
});

class App extends Component {
  constructor(props) {
    super(props);
    for (let key in localStorage) {
      if (key === "authUser") {
        this.setCurrentUser(localStorage.getItem(key));
      }
    }
    this.state = {
      isAdmin: false,
    };
  }

  componentDidMount() {
    authRef.onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          console.log("Auth state changed");
          this.setState({
            isAdmin: !!idTokenResult.claims.admin,
          });
        });
      }
      this.setCurrentUser(user);
    });
  }

  setCurrentUser = (user) => {
    if (user && !this.isExpiredSignIn(user)) {
      store.dispatch({
        type: CREATE_SESSION,
        payload: user,
      });
    } else {
      store.dispatch({
        type: DESTROY_SESSION,
      });
    }
  };

  isExpiredSignIn = (user) => {
    const numDaysTillLastSignIn = moment().diff(moment(user.metadata?.lastSignInTime), "days");
    return numDaysTillLastSignIn > 1;
  };

  routes = () => {
    return (
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => {
            return <Login setCurrentUser={this.setCurrentUser} {...props} />;
          }}
        />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <PrivateRoute exact path="/" component={Dashboard} isAdmin={this.state.isAdmin} />
        <PrivateRoute
          exact
          path="/risk-management"
          component={RiskManagement}
          isAdmin={this.state.isAdmin}
        />
        <PrivateRoute
          exact
          path="/properties/add-property"
          component={AddProperty}
          isAdmin={this.state.isAdmin}
        />
        <PrivateRoute
          exact
          path="/properties/add-transactions"
          component={AddLedgerEntry}
          isAdmin={this.state.isAdmin}
        />
        <PrivateRoute
          exact
          path="/properties/add-depreciation-schedule"
          component={AddDepreciationSchedule}
          isAdmin={this.state.isAdmin}
        />
        <PrivateRoute exact path="/settings" component={Settings} isAdmin={this.state.isAdmin} />
        <PrivateRoute exact path="/reports" component={Reports} isAdmin={this.state.isAdmin} />
        <PrivateRoute path="/properties/:id" component={Property} isAdmin={this.state.isAdmin} />
        <AdminRoute exact path="/admin" component={Admin} isAdmin={this.state.isAdmin} />
        <Route component={Error} />
      </Switch>
    );
  };

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <div className="App">
              <Helmet>
                <title>Investment Property Tracker | App</title>
                <meta
                  name="description"
                  content="Forget spreadsheets! Track your Investment Property data to calculate your income and cashflows. Make projections and generate financial reports for tax time."
                />
                <meta
                  name="og:title"
                  property="og:title"
                  content="Investment Property Tracker | Investing Made Easy"
                />
                <meta
                  name="og:description"
                  property="og:description"
                  content="Forget spreadsheets! Track your Investment Property data to calculate your income and cashflows. Make projections and generate financial reports for tax time."
                />
              </Helmet>
              {this.routes()}
            </div>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;

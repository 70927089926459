import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signIn, signOut } from "../../actions/authActions";
import LoginForm from "../forms/LoginForm";

const Login = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    if (props.location.state && props.location.state.forgotPassword) {
      setShowBar(true);
    }
  }, [props.location.state]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await props.signIn(values.email, values.password, props.history);
    } catch (err) {
      if (err.code.includes("wrong-password") || err.code.includes("user-not-found")) {
        setError("The entered email or password is incorrect");
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const { from } = props.location.state || { from: { pathname: "/" } };
  if (props.auth.isAuthenticated) {
    props.setCurrentUser(props.auth.user);
    return <Redirect to={from} />;
  }

  return (
    <div>
      <Container maxWidth="xs" style={{ height: "100vh" }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            style={{
              backgroundColor: "#2962FF",
              margin: "8px",
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h4">Log in</Typography>
          <LoginForm onSubmit={onSubmit} isLoading={isLoading} err={error} />
        </div>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={`top,center`}
        open={showBar}
        autoHideDuration={3000}
        onClose={() => setShowBar(false)}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: "#43a047" }}
          message={
            <span>
              <CheckCircleIcon style={{ marginRight: "10px" }} />
              Please check your email for password reset instructions.
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { signIn, signOut })(Login);

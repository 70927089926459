import axios from "axios";
import { v4 as uuid } from "uuid";
import {
  getDefaultProperty,
  getDefaultTransactions,
} from "../components/forms/property/DefaultProperty";
import { authRef, dbRef } from "../firebase";
import { addSampleTransactions } from "./ledgerActions";
import { addNewSampleProperty } from "./propertyActions";
import { GET_USER_DETAILS, RESET_APP } from "./types";

export const signIn = (email, password, history) => async (dispatch) => {
  try {
    await authRef.signInWithEmailAndPassword(email, password);
    history.push("/");
  } catch (err) {
    throw err;
  }
};

export const signUp = (email, password, fullName, history) => async (dispatch) => {
  try {
    const user = await authRef.createUserWithEmailAndPassword(email, password);
    await dbRef.child(`users/${user.user.uid}`).set({
      fullName: fullName,
      dilutionFactor: 100,
      inflationRate: 2.5,
      capitalGrowthRate: 2.5,
    });
    history.push("/");
  } catch (err) {
    throw err;
  }
};

export const signUpWithSampleProperty = (email, password, fullName, history) => async (
  dispatch
) => {
  try {
    const user = await authRef.createUserWithEmailAndPassword(email, password);
    await dbRef.child(`users/${user.user.uid}`).set({
      fullName: fullName,
      dilutionFactor: 100,
      inflationRate: 2.5,
      capitalGrowthRate: 2.5,
    });
    const propertyId = uuid();
    const loanId = uuid();
    const sampleProperty = getDefaultProperty(propertyId, loanId);
    const sampleTransactions = getDefaultTransactions(propertyId, loanId);
    await addNewSampleProperty(sampleProperty, user.user.uid);
    await addSampleTransactions(sampleTransactions, propertyId, user.user.uid);
    history.push("/");
  } catch (err) {
    throw err;
  }
};

export const signOut = () => async (dispatch) => {
  try {
    await authRef.signOut();
    dispatch({
      type: RESET_APP,
    });
  } catch (err) {
    throw err;
  }
};

export const getUserDetails = (uid) => async (dispatch) => {
  try {
    const snapshot = await dbRef.child(`users/${uid}`).once("value");
    const val = snapshot.val() || {};
    dispatch({ type: GET_USER_DETAILS, payload: val });
    return val;
  } catch (err) {
    throw err;
  }
};

// Update user details
export const updateUserDetails = (uid, updatedUserDetails) => async (dispatch) => {
  console.log("Updating user details");
  try {
    await dbRef.child(`users/${uid}`).update(updatedUserDetails);
    dispatch({ type: GET_USER_DETAILS, payload: updatedUserDetails });
  } catch (err) {
    return err;
  }
};

export const sendEmail = ({ name, email, message }) => async (dispatch) => {
  const data = { name, email, message };
  const emailCF = "https://us-central1-investment-property-tracker.cloudfunctions.net/sendEmail";
  axios
    .post(emailCF, data)
    .then((res) => console.log("Sent successfully"))
    .catch((err) => console.log(err));
};

export const sendFeedback = (uid, title, comment) => async (dispatch) => {
  const payload = {
    uid,
    title,
    comment,
    dateSubmitted: new Date().toISOString(),
  };
  try {
    await dbRef.child(`feedback/${uuid()}`).set(payload);
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = (email, history) => async (dispatch) => {
  try {
    await authRef.sendPasswordResetEmail(email);
    history.push("/login", { forgotPassword: true });
  } catch (err) {
    throw err;
  }
};
